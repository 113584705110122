<template>
  <div class="home-page">
    <div class="welcome-strip">
      <div class="welcome-strip_message">
        <h2>{{ $t("home.text.welcome", [$store.state.user.userFullname]) }}</h2>
      </div>
      <div class="welcome-strip_info">
        <div class="form-item">
          <div>{{ $t("home.text.workDuration") }}</div>
          <div class="form-item_duration">{{ getWorkDuration($store.state.mainPageData.contactFirstContract?.contractStart) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("home.label.currentAgr") }}</label>
          <div class="bold" v-show="$store.state.mainPageData?.contactActualContracts?.length">
            <p v-for="agreement in $store.state.mainPageData?.contactActualContracts" :key="agreement.contractCode">
              {{ $t("home.text.agreementDate", [agreement.contractCode, formatDateField(agreement.contractStart)]) + (agreement.contractProlongation ? $t("home.label.prolongation") : "") }}
            </p>
          </div>
          <div v-show="!$store.state.mainPageData?.contactActualContracts?.length">—</div>
        </div>
      </div>
      <div class="welcome-strip_info">
        <div class="form-item">
          <label>{{ $t("home.label.firstAgr") }}</label>
          <div class="bold">
            {{ $t("home.text.agreementDate", [$store.state.mainPageData.contactFirstContract?.contractCode, formatDateField($store.state.mainPageData.contactFirstContract?.contractStart)]) }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-info-block">
      <div>
        <div class="tile-block horizontal-layout">
          <base-tile :description="$t('home.tile.description.shipment')" icon="fa-solid fa-clipboard-list" :number="$store.state.mainPageData.contactOrdersQuantity" :showNumber="true" routeName="shipment" :annotation="$t('title.annotation.shipments')"/>
          <base-tile :description="$t('home.tile.description.requests')" icon="fa-solid fa-comments" :number="$store.state.mainPageData.contactRequestsQuantity" :showNumber="true" routeName="requests" class="next-tile" :annotation="$t('title.annotation.requests')"/>
        </div>
        <div class="tile-block horizontal-layout">
          <base-tile :description="$t('home.tile.description.billings')" icon="fa-solid fa-file-invoice-dollar" :showNumber="false" routeName="billings" number="0" />
          <base-tile :description="$t('home.tile.description.reports')" icon="fa-solid fa-chart-column" number="3" :showNumber="false" routeName="reports" class="next-tile" />
        </div>
      </div>
      <div class="news-block">
        <div class="vertical-layout">
          <h3 class="bold">{{ $t("home.company.about") }}</h3>
          <div class="company-welcome-block margin-top">
            <img src="@/assets/image2.jpg" />
            <div class="company-info">
              <div class="horizontal-layout">
                <div style="width: 50%">
                  <p>{{ $t("home.company.market") }}</p>
                  <div class="horizontal-layout">
                    {{ $t("home.company.since") }}
                    <h2 class="bold margin-left">2008</h2>
                  </div>
                  <p v-show="$i18n.locale === 'ru'">{{ $t("home.company.year") }}</p>
                </div>
                <div style="width: 50%">
                  <p>{{ $t("home.company.unite") }}</p>
                  <h2 class="bold">8</h2>
                  <p>{{ $t("home.company.countries") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vertical-layout">
          <h3 class="bold margin-top-large">{{ $t("home.news.title") }}</h3>
          <div class="telegram-news-block margin-top">
            <div v-for="(oneNews, index) in $store.state.mainPageData?.news" :key="index" class="news-row">
              <p>{{ oneNews.newsTitle }}</p>
              <label>{{ formatDateField(oneNews.newsDate) }}</label>
            </div>
            <div class="news-footer">
              <p>{{ $t("home.news.moreNews") }}</p>
              <a class="telegram-link" href="https://t.me/talogistics" target="_blank">
                <font-awesome-icon icon="fa-solid fa-paper-plane" />
                <span class="margin-left">talogistics</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import BaseTile from "@/components/commonLib/BaseTile.vue";

export default {
  components: { BaseTile },
  data() {
    return {
      isLoading: false
    };
  },
  created() {
    this.isLoading = this.$store.state.mainPageData?.contactOrdersQuantity > -1 ? false : true;
    MockService.getMainPageData()
      .then((oResponse) => {
        this.$store.state.mainPageData = oResponse.data;
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$accentColor: #f26c41;
.home-page {
  overflow: auto;
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: fixed;
    left: 0rem;
    top: 0rem;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("../assets/shipment-equip.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  & > div {
    position: relative;
  }

  .home-info-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .home-info-block > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .news-block {
    margin: 1rem 0;
    max-width: 25rem;
    padding: 0.5rem;

    .company-welcome-block {
      box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
      margin-top: 0.5rem;
      background-color: white;

      .company-info {
        padding: 1rem;
      }
    }

    .telegram-news-block {
      box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
      padding: 1rem;
      background-color: white;

      label {
        color: #c1c1c1;
      }

      .news-row {
        margin-bottom: 0.5rem;
      }

      .news-footer {
        border-top: 1px solid #c1c1c120;
        padding-top: 0.5rem;
        text-align: end;

        .telegram-link {
          span {
            font-size: 0.825rem;
          }
          svg {
            transform: rotate(15deg);
          }
        }
      }
    }
  }

  .tile-block {
    margin: 1rem 0;
    flex-wrap: nowrap;
    gap: 0.2rem;
  }
  @media screen and (min-width: 600px) {
    .tile-block {
      padding: 0.5rem;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  @media screen and (min-width: 900px) {
    .home-info-block > div {
      width: 50%;
    }
  }

  .form-item .form-item_duration {
    color: $accentColor;
    font-size: 1rem;
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    .tile-block {
      justify-content: space-around;
    }
    .welcome-strip {
      margin-top: 0.5rem;
    }
  }
}

.home-page::-webkit-scrollbar {
  display: none;
}

.welcome-strip {
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;

  .welcome-strip_message {
    margin-bottom: 0.5rem;
  }
}

.welcome-strip > div {
  min-width: 300px;
}

.welcome-strip h5 {
  font-size: 0.725rem;
  text-align: start;
}

.welcome-strip > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.welcome-strip_info {
  margin-bottom: 0.5rem;
}
</style>
