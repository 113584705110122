<template>
  <div class="circle-icon" :style="{ 'background-color': backgroundColor }">
    <font-awesome-icon :icon="'fa-solid ' + icon" />
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "#c1c1c1",
    },
    icon: {
      type: String,
      default: "fa-user",
    },
  },
};
</script>

<style>
.circle-icon {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-icon svg {
  color: #ffffff;
  height: 1rem;
}
</style>