<template>
  <div class="bar-chart">
    <div style="display: inline-block; width: 100%">
      <Bar style="display: none" :options="chartOptions" :data="chartData" id="shipmentsUnitedReportV1ID" height="400" />
      <h3 style="color: #2c3e50; text-align: center">{{ $t('txt.inDevelopment') }}</h3>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import MockService from "@/services/MockService";

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale);
ChartJS.defaults.font.family = "Montserrat";

export default {
  components: {
    Bar
  },
  props: {
    dateRange: Object,
    reportData: {
      type: new Array(),
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            onClick: (e) => {
              return;
            },
            position: "top",
            labels: {
              boxWidth: 20
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "USD"
            },
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line no-unused-vars
              callback: function (value, index, ticks) {
                return "$" + value;
              }
            }
          }
        }
      }
    };
  },
  watch: {
    reportData() {
      let oChartData = {
        labels: [],
        datasets: []
      };
      const oReportChartData = this.reportData[0]?.chartData;
      if (oReportChartData) {
        let oDataset = {
          label: this.$t("report.shipments.averageSum"),
          backgroundColor: "#2c3e50",
          maxBarThickness: 25,
          data: oReportChartData.map((oSumElement) => oSumElement.sum)
        };
        oChartData.labels = oReportChartData.map((oSumElement) => this.$moment(oSumElement.date).format("MMM YY"));
        oChartData.datasets = [oDataset];
      }
      this.chartData = oChartData;
    }
  },
  created() {
    //this.requestShipmentData();
    // this.createTempRep();
  },
  methods: {
    requestShipmentData() {
      MockService.getShipmentsForChart()
        .then((oResponse) => {
          this.createShipmentsAllReport(oResponse.data);
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        });
    },
    createShipmentsAllReport(oReceivedData) {
      let aLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      let aAmountOfMonth = [[], [], [], [], [], [], [], [], [], [], [], []];

      let oAll = {
        label: "Средняя сумма",
        backgroundColor: "#2c3e50",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      };

      oReceivedData.forEach((oNote) => {
        let sMonth = oNote.date_shipment_plan.split(".")[1];
        let iIndexLabel = aLabels.findIndex((oLabel) => oLabel === Number(sMonth));
        aAmountOfMonth[iIndexLabel].push(Number(oNote.average_sum));
      });

      aAmountOfMonth.forEach((aElement, index) => {
        if (aElement.length > 0) {
          oAll.data[index] = aElement.reduce((acc, oValue) => oValue + acc, 0) / aElement.length;
        }
      });

      let aLabel = ["Январь'22", "Февраль'22", "Март'22", "Апрель'22", "Май'22", "Июнь'22", "Июль'22", "Август'22", "Сентябрь'22", "Октябрь'22", "Ноябрь'22", "Декабрь'22"];

      let oChartData = JSON.parse(JSON.stringify(this.chartData));
      oChartData.labels = aLabel;
      oChartData.datasets = [oAll];

      this.chartData = oChartData;
    },

    createTempRep() {
      let oTempData = [
        {
          date: "2023-01-01T00:00:00Z",
          sum: 1000
        },
        {
          date: "2023-02-01T00:00:00Z",
          sum: 120
        },
        {
          date: "2023-03-01T00:00:00Z",
          sum: 0
        },
        {
          date: "2023-04-01T00:00:00Z",
          sum: 546
        }
      ];

      let oDataset = {
        label: this.$t("report.shipments.averageSum"),
        backgroundColor: "#2c3e50",
        data: oTempData.map((oSumElement) => oSumElement.sum)
      };

      let oChartData = JSON.parse(JSON.stringify(this.chartData));
      oChartData.labels = oTempData.map((oSumElement) => this.$moment(oSumElement.date).format("MMM YY"));
      oChartData.datasets = [oDataset];

      this.chartData = oChartData;
    }
  }
};
</script>

<style>
</style>
