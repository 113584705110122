<template>
  <div class="rate-dialog">
    <header>
      <!-- <h2>Вы оценили перевозку на {{ rate }}</h2> -->
      <the-rate size="is-small" :rating="rating" disabled />
    </header>
    <section>
      <div v-if="Number(rate) > 3">
        <p>
          <b>{{ $t("shipment.rateDialog.like") + ":" }}</b>
        </p>
        <div class="rate-buttons-container">
          <b-checkbox-button v-for="reason in positiveList" :key="reason.key" v-model="reasonGroup" :native-value="reason"
            type="is-primary" size="is-small">
            {{ reason.text }}
          </b-checkbox-button>
        </div>
      </div>
      <div v-else>
        <p>
          <b>{{ $t("shipment.rateDialog.unlike") + ":" }}</b>
        </p>
        <div class="rate-buttons-container">
          <b-checkbox-button v-for="reason in negativeList" :key="reason.key" v-model="reasonGroup" :native-value="reason"
            type="is-primary" size="is-small">
            {{ reason.text }}
          </b-checkbox-button>
        </div>
      </div>
      <div class="feedbackText">
        <b-input maxlength="200" type="textarea" size="is-small" resize="none" v-model="feedbackTextComment"></b-input>
      </div>
    </section>
    <footer>
      <b-button type="is-primary is-small" @click="sendRateReason">{{ $t("btn.send") }}</b-button>
    </footer>
  </div>
</template>

<script>
import MockService from "@/services/MockService.js";
import TheRate from "@/components/commonLib/TheRate.vue";
export default {
  components: {
    TheRate
  },
  props: {
    rate: {
      type: Number,
      default: 0
    },
    shipmentId: {
      type: String
    }
  },
  computed: {
    rating() {
      return this.rate;
    }
  },
  watch: {
    rate() {
      this.reasonGroup = [];
    }
  },
  data() {
    return {
      reasonGroup: [],
      positiveList: [
        {
          key: "PCP",
          text: this.$t("shipment.rateDialog.positive.paymentConditions")
        },
        {
          key: "PCD",
          text: this.$t("shipment.rateDialog.positive.cargoDelivery")
        },
        {
          key: "PCS",
          text: this.$t("shipment.rateDialog.positive.cargoSafety")
        },
        {
          key: "PD",
          text: this.$t("shipment.rateDialog.positive.documentManagement")
        },
        {
          key: "PMC",
          text: this.$t("shipment.rateDialog.positive.communicationWithTheManager")
        }
      ],
      negativeList: [
        {
          key: "PC",
          text: this.$t("shipment.rateDialog.negative.paymentConditions")
        },
        {
          key: "PCDD",
          text: this.$t("shipment.rateDialog.negative.delayOfCargo")
        },
        {
          key: "PCSB",
          text: this.$t("shipment.rateDialog.negative.cargoSafety")
        },
        {
          key: "PDP",
          text: this.$t("shipment.rateDialog.negative.documentManagement")
        },
        {
          key: "PMCB",
          text: this.$t("shipment.rateDialog.negative.communicationWithTheManager")
        }
      ],
      feedbackTextComment: ""
    };
  },
  methods: {
    sendRateReason() {
      const sId = this.shipmentId;
      const sFeedback = this.reasonGroup.map((oReason) => oReason.text.toLowerCase()).join(", ");
      let oRateBody = { feedbackRating: this.rate };
      if (sFeedback) {
        oRateBody["feedbackComment"] = sFeedback;
      }
      if (this.feedbackTextComment) {
        oRateBody["feedbackTextComment"] = this.feedbackTextComment;
      }

      MockService.setRate(sId, oRateBody)
        .then(() => {
          this.successMessage(this.$t("message.rate.graded", [this.rate]));
        })
        .finally(() => {
          this.reasonGroup = [];
          this.$emit("closePopup");
          this.feedbackTextComment = "";
        });
    }
  }
};
</script>

<style lang="scss">
.rate-dialog {
  height: 100%;
  width: 100%;
  background-color: white;

  header {
    height: 2rem;
    padding: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;

    .icon.is-small {
      width: 1.5rem;

      svg {
        height: 1.5rem;
      }
    }

    //   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }

  section {
    padding: 0.5rem;
    text-align: center;

    .rate-buttons-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.2rem;
      margin-top: 0.5rem;
      justify-content: center;

      .control {
        width: min-content;
      }
    }

    .feedbackText {
      margin-top: 0.5rem;

      textarea {
        resize: none;
      }
    }
  }

  footer {
    //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    padding: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }
}
</style>
