<template>
  <div class="detailed-form">
    <div class="form-container">
      <div class="wrapping-form-block">
        <div class="form-item wrap-form-full">
          <label>{{ $t("shipment.label.orderID") }}</label>
          <div class="bold">{{ formatTextField(shipment?.general?.orderNumber) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipper") }}</label>
          <div class="bold">
            {{ formatTextField(shipment?.general?.orderShipper) }}
          </div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.direction") }}</label>
          <div class="bold">
            {{ shipment?.general?.orderFrom }} —
            {{ shipment?.general?.orderTo }}
          </div>
        </div>
        <div v-if="checkValidDate(shipment?.general?.orderDepartureDateActual)" class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentDateFact") }}</label>
          <div>{{ formatDateField(shipment?.general?.orderDepartureDateActual) }}</div>
        </div>
        <div v-else class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.general?.orderDepartureDatePlan) }}</div>
        </div>
        <div v-if="checkValidDate(shipment?.general?.orderArrivalDateActual)" class="form-item wrap-form">
          <label>{{ $t("shipment.label.arrivalDateFact") }}</label>
          <div>{{ formatDateField(shipment?.general?.orderArrivalDateActual) }}</div>
        </div>
        <div v-else class="form-item wrap-form">
          <label>{{ $t("shipment.label.arrivalDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.general?.orderArrivalDatePlan) }}</div>
        </div>
      </div>
      <div class="wrapping-form-block">
        <div class="form-item wrap-form-full">
          <label>{{ $t("shipment.label.cargo") }}</label>
          <div>{{ formatTextField(shipment?.general?.orderCargoName) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.weightDeclared") }}</label>
          <div>{{ formatDecimalNumberField(shipment?.general?.orderWeightPlan) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.weightFact") }}</label>
          <div>{{ formatDecimalNumberField(shipment?.general?.orderWeightActual) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.volumeDeclared") }}</label>
          <div>{{ formatDecimalNumberField(shipment?.general?.orderVolumePlan) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.volumeFact") }}</label>
          <div>{{ formatDecimalNumberField(shipment?.general?.orderVolumeActual) }}</div>
        </div>
      </div>
      <div class="wrapping-form-block">
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.cargoReceiver") }}</label>
          <div>{{ formatTextField(shipment?.general?.orderConsignee) }}</div>
        </div>
        <div class="form-item wrap-form-full">
          <label>{{ $t("shipment.label.manager") }}</label>
          <div>
            <div class="horizontal-layout align-center">
              {{ shipment?.general?.orderKAM }}
              <b-button type="is-warning is-small" icon-right="fa-comments" class="margin-left customButton" inverted
                :disabled="!shipment?.general?.orderId" @click="onCreateRequest" />
            </div>
            <p>
              <a :href="'tel:' + shipment?.general?.orderKAMPhoneNumber">{{ shipment?.general?.orderKAMPhoneNumber }}</a>
            </p>
            <b-button style="display: none" type="is-warning is-small" class="button-no-padding" inverted
              :disabled="!shipment?.general?.orderId" @click="onCreateRequest">{{ $t("btn.contactManager") }}</b-button>
          </div>
        </div>

        
      </div>
      <div class="wrapping-form-block colorfullList">
        <div class="form-item wrap-form shipment-status-block list-item">
          <div class="state" :class="getStatusClass(shipment?.general?.orderActualStatus?.statusId, shipment?.general?.orderArrivalDateActual)" />
          <div class="status-block">
            <label>{{ $t("shipment.label.shipmentStatus") }}</label>
            <div class="shipment-status">{{ formatTextStatus(shipment?.general?.orderActualStatus?.statusId) }}</div>
            <div class="status-detail">
              <div :class="shipment?.general?.orderFinished ? 'completedStatus' : 'expectedStatus'">
                <font-awesome-icon
                  :icon="shipment?.general?.orderFinished ? 'fa-solid fa-check' : 'fa-solid fa-ellipsis'" />
                <div v-if="shipment?.general?.orderFinished">{{ $t("shipment.label.status.deliveryCompleted") }}</div>
                <div v-else >{{ $t("shipment.label.status.deliveryExpected") }}</div>
              </div>
              <div :class="shipment?.general?.orderAVR ? 'completedStatus' : 'expectedStatus'">
                <font-awesome-icon :icon="shipment?.general?.orderAVR ? 'fa-solid fa-check' : 'fa-solid fa-ellipsis'" />
                <div v-if="shipment?.general?.orderAVR">{{ $t("shipment.label.status.avrExposed") }}</div>
                <div v-else >{{ $t("shipment.label.status.avrExpected") }}</div>
              </div>
              <div :class="shipment?.general?.orderPaid ? 'completedStatus' : 'expectedStatus'">
                <font-awesome-icon :icon="shipment?.general?.orderPaid ? 'fa-solid fa-check' : 'fa-solid fa-ellipsis'" />
                <div v-if="shipment?.general?.orderPaid">{{ $t("shipment.label.status.paid") }}</div>
                <div v-else >{{ $t("shipment.label.status.paymentExpected") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <the-popup ref="popup" :closable="isCreateRequestDialogClose" height="20rem" width="20rem">
      <create-request-dialog :requestDetails="shipment" @banCloseDialog="banCloseDialog"
        @successfullySent="messageCreated"></create-request-dialog>
    </the-popup>
  </div>
</template>

<script>
import ThePopup from "@/components/commonLib/ThePopup.vue";

// import { EventBus } from '@/event-bus';
import CreateRequestDialog from "@/components/requests/CreateRequestDialog.vue";
export default {
  components: {
    ThePopup,
    CreateRequestDialog
  },
  data() {
    return {
      isCreateRequestDialogClose: true
    };
  },
  props: {
    shipment: {
      type: Object
    }
  },
  methods: {
    onCreateRequest() {
      this.$refs.popup.open();
    },
    messageCreated(sMessageId) {
      this.$refs.popup.close();
      this.isCreateRequestDialogClose = true;
      this.$router.push({
        name: "requestDetail",
        params: { requestId: sMessageId }
      });
    },
    banCloseDialog() {
      this.isCreateRequestDialogClose = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 2vw;
}
.detailed-form {
  margin-bottom: 1rem;
}

.button-no-padding {
  padding: 0;
}

.shipment-status-block {
  background-color: #c1c1c110;
  height: 8.5rem;

  .state {
    height: 100%;
  }

  .status-block {
    padding: 1rem;

    .shipment-status {
      font-weight: 700;
    }

    .status-detail {
      padding-left: 0.5rem;
      padding-top: 0.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.5rem;

      .completedStatus {
        display: flex;
        gap: 0.5rem;
        color: #949494 !important;
      }

      .expectedStatus {
        display: flex;
        gap: 0.5rem;
        color: #000000 !important;        
      }
    }
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .wrapping-form-block .shipment-status-block {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) and (orientation: portrait) {
  .wrapping-form-block .shipment-status-block {
    width: 100% !important;
  }
}
</style>
