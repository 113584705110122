<template>
  <div class="filters-dialog">
    <div class="settings-block">
      <div class="sorters-block" v-show="settings.sorters.length">
        <h2 class="filter-block-header">{{ $t("filters.group.sortBy") }}</h2>
        <div class="radio-button-group">
          <div class="form_radio_btn" v-for="(field, index) in settings.sorters" :key="field.name">
            <input :id="'radio-' + index" type="radio" name="radio" :value="field.name" @change="handleSorterGroup" v-bind:checked="field.active" />
            <label :for="'radio-' + index">{{ $t("filters." + field.name) }}</label>
          </div>
        </div>
      </div>
      <div class="filters-block">
        <h2 class="filter-block-header">{{ $t("filters.group.filterBy") }}</h2>
        <div>
          <div v-for="field in settings.filters" :key="field.name">
            <div v-if="field.type === 'rangedatepicker'">
              <h4 class="customLabel">{{ $t("filters." + field.name) }}</h4>
              <div class="horizontal-layout range-field-block">
                <date-picker prefix-class="xmx" v-model="field.value1" :editable="false" :placeholder="$t('filters.since')" format="DD.MM.YYYY" :lang="$store.state.user.userLanguage.toLowerCase()"> </date-picker>
                <date-picker prefix-class="xmx" v-model="field.value2" :editable="false" :placeholder="$t('filters.to')" format="DD.MM.YYYY" :lang="$store.state.user.userLanguage.toLowerCase()"> </date-picker>
              </div>
            </div>
            <div v-else-if="field.type === 'dropdown'">
              <h4 class="customLabel">{{ $t("filters." + field.name) }}</h4>
              <div>
                <b-dropdown size="is-small" v-model="field.value1" multiple :mobile-modal="false" class="customDropDown">
                  <template #trigger>
                    <b-taginput v-model="field.value1" :data="statuses" ellipsis field="text" autocomplete size="is-small" readonly>
                      <template v-slot="props">
                        <strong>{{ props.option.text }}</strong>
                      </template>
                    </b-taginput>
                  </template>
                  <b-dropdown-item size="is-small" v-for="item in statuses" :key="item.text" :value="item.text">{{ item.text }}</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div v-else>
              <h4 class="customLabel">{{ $t("filters." + field.name) }}</h4>
              <div><b-input v-model="field.value1" size="is-small" :placeholder="$t('txt.enterText')" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filters-footer">
      <b-button type="is-primary is-small" class="margin-left" v-on:click="onResetFilters">{{ $t("btn.reset") }}</b-button>
      <b-button type="is-primary is-small" class="margin-left" v-on:click="onSubmitFilters">{{ $t("btn.apply") }}</b-button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  props: {
    defaultSettings: {
      type: Object
    },
    setSettings: {
      type: Object
    }
  },
  data() {
    return {
      selectedOptions: [],
      statuses: [{ text: "В пути" }, { text: "Не отправлен" }, { text: "Растаможен" }],
      settings: JSON.parse(JSON.stringify(this.defaultSettings))
    };
  },
  watch: {
    settings() {}
  },
  mounted() {
    if (!this.setSettings) {
      this.settings = JSON.parse(JSON.stringify(this.defaultSettings));
    } else {
      this.settings = this.setSettings;
    }
  },
  methods: {
    handleSorterGroup(oEvent) {
      this.settings.sorters.forEach((oSorter) => {
        if (oSorter.name === oEvent.target._value) {
          oSorter.active = true;
        } else {
          oSorter.active = false;
        }
      });
    },
    onResetFilters() {
      this.settings = JSON.parse(JSON.stringify(this.defaultSettings));
    },
    onSubmitFilters() {
      let aPreparedSetting = {
        sorters: {},
        filters: []
      };
      aPreparedSetting.sorters = this.settings.sorters.find((oSorter) => oSorter.active);
      this.settings.filters.forEach((oFilter) => {
        if (oFilter.type === "dropdown" && oFilter.value1.length) {
          aPreparedSetting.filters.push(oFilter);
          return;
        } else if (oFilter.value1 || oFilter.value2) {
          aPreparedSetting.filters.push(oFilter);
        }
      });
      this.$emit("filter", aPreparedSetting);
    }
  }
};
</script>

<style lang="scss">
.customDropDown {
  overflow: visible;
  .modal-card-body {
    overflow: visible;
  }
}

.filters-dialog {
  background-color: #fff;
  // width: 30rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  gap: 1rem;

  .settings-block {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: -webkit-fill-available;

    gap: 1rem;
  }

  .customLabel {
    color: #2c3e5080;
  }
  .customLabel::after {
    content: ":";
  }
  .filter-block-header {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }

  .range-field-block {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .filters-block {
    .xmx-input:hover,
    .xmx-input:focus {
      border-color: #2c3e50;
    }
    a.dropdown-item.is-active {
      background: #c1c1c180;
    }
    .dropdown {
      width: 100%;

      input {
        max-width: 2rem;
      }
      .dropdown-trigger {
        width: 100%;
      }

      .dropdown-trigger:focus-visible {
        border: none !important;
        outline: none;
      }
      .dropdown-menu {
        width: 100%;
        padding-top: 0;

        .dropdown-content {
          padding-top: 0;
          padding-bottom: 0;

          a {
            font-size: 0.725rem;
          }
        }
      }
    }
  }

  .filters-block > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .filters-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .radio-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .form_radio_btn {
    display: inline-block;
    margin-right: 10px;
  }
  .form_radio_btn input[type="radio"] {
    display: none;
  }
  .form_radio_btn label {
    display: inline-block;
    cursor: pointer;
    padding: 0px 15px;
    line-height: 34px;
    border: 1px solid #999;
    border-radius: 6px;
    user-select: none;
  }

  /* Checked */
  .form_radio_btn input[type="radio"]:checked + label {
    background: #2c3e50;
    color: #fff;
    border: none;
  }

  /* Hover */
  .form_radio_btn label:hover {
    color: #666;
  }

  /* Disabled */
  .form_radio_btn input[type="radio"]:disabled + label {
    background: #efefef;
    color: #666;
  }
}
</style>