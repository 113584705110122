<template>
  <div class="write-to-dev-block">
    <div class="form-block">
      <div class="form-item">              
        <label required>Опишите проблему</label>
        <div>
          <b-input v-model="text" type="textarea" size="is-small" custom-class="reqTextArea"></b-input>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <b-button type="is-primary is-small" class="margin-left" @click="onSendRequest">Отправить</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: ""
    };
  },
  methods: {
    onSendRequest() {
        
    }
  },

};
</script>

<style lang="scss">
.write-to-dev-block {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  .form-block {
    width: 100%;
    display: flex;
    flex-direction: column;

    .form-item {
      width: 100%;
      div {
        width: 100%;
      }
    }

    .reqTextArea {
      resize: none;
    }
  }

  .dialog-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>