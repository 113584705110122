<template>
  <div class="balance-box billing-box-shadow" style="display: inline-block; width: 100%;}">
    <h3 style="color: #2c3e50; text-align: center">{{ $t('txt.inDevelopment') }}</h3>
    <!-- <div class="balance-box-header">
      <label style="font-size: 0.7rem">{{ $t("billings.box.actualDate", [$moment().format("DD.MM.YYYY, HH:mm:ss")]) }}</label>
      <div>
        <b-switch v-model="isSwitchedCompanies" size="is-small">{{ $t("billings.balance.label.organization") }}</b-switch>
      </div>
    </div>
    <div v-if="isSwitchedCompanies" class="balance-organisations" v-show="balance.balanceByOrganization?.length !== 0">
      <div class="table-box-desktop" v-for="organization in balance.balanceByOrganization" :key="organization.organizationName">
        <div class="table-header">
          <label class="company-name">{{ organization.organizationName }}</label>
          <label>{{ $t("billings.balance.table.row.balance") }}</label>
          <label>{{ $t("billings.balance.table.row.accrued") }}</label>
          <label>{{ $t("billings.balance.table.row.paidFor") }}</label>
        </div>
        <div class="table-body" v-for="currency in organization.organizationBalances" :key="currency.balanceCurrency">
          <p>{{ currency.balanceCurrency }}</p>
          <p class="balance" :class="Number(formatDecimalInvertedNumberField(currency.balanceValue)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceValue) }}</p>
          <p :class="Number(formatDecimalInvertedNumberField(currency.balanceCredited)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceCredited) }}</p>
          <p :class="Number(formatDecimalInvertedNumberField(currency.balancePaid)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balancePaid) }}</p>
        </div>
      </div>
    </div>
    <div v-else class="table-box-desktop" v-show="balance.balanceByCurrency?.length !== 0">
      <div class="table-header">
        <br />
        <label>{{ $t("billings.balance.table.row.balance") }}</label>
        <label>{{ $t("billings.balance.table.row.accrued") }}</label>
        <label>{{ $t("billings.balance.table.row.paidFor") }}</label>
      </div>
      <div class="table-body" v-for="currency in balance.balanceByCurrency" :key="currency.balanceCurrency">
        <p>{{ currency.balanceCurrency }}</p>

        <p class="balance" :class="Number(formatDecimalInvertedNumberField(currency.balanceValue)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceValue) }}</p>
        <p :class="Number(formatDecimalInvertedNumberField(currency.balanceCredited)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceCredited) }}</p>
        <p :class="Number(formatDecimalInvertedNumberField(currency.balancePaid)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balancePaid) }}</p>
      </div>
    </div>
    <div v-if="isSwitchedCompanies" class="table-box-mobile" v-show="balance.balanceByOrganization?.length !== 0">
      <div v-for="organization in balance.balanceByOrganization" :key="organization.organizationName" class="balance-organisations" >
        <h1 class="company-name">{{ organization.organizationName }}</h1>
        <div v-for="currency in organization.organizationBalances" :key="currency.balanceCurrency" class="balance-content">
          <h1>{{ currency.balanceCurrency }}</h1>
          <div class="form-item">
            <label>{{ $t("billings.balance.table.row.balance") }}</label>
            <div :class="Number(formatDecimalInvertedNumberField(currency.balanceValue)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceValue) }}</div>
          </div>
          <div class="form-item">
            <label>{{ $t("billings.balance.table.row.accrued") }}</label>
            <div :class="Number(formatDecimalInvertedNumberField(currency.balanceCredited)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceCredited) }}</div>
          </div>
          <div class="form-item">
            <label>{{ $t("billings.balance.table.row.paidFor") }}</label>
            <div  :class="Number(formatDecimalInvertedNumberField(currency.balancePaid)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balancePaid) }}</div>
          </div>
      </div>
      </div>
    </div>
    <div v-else class="table-box-mobile" v-show="balance.balanceByCurrency?.length !== 0">
      <div v-for="currency in balance.balanceByCurrency" :key="currency.balanceCurrency" class="balance-content">
        <h1>{{ currency.balanceCurrency }}</h1>
        <div class="form-item">
          <label>{{ $t("billings.balance.table.row.balance") }}</label>
          <div :class="Number(formatDecimalInvertedNumberField(currency.balanceValue)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceValue) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("billings.balance.table.row.accrued") }}</label>
          <div :class="Number(formatDecimalInvertedNumberField(currency.balanceCredited)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balanceCredited) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("billings.balance.table.row.paidFor") }}</label>
          <div  :class="Number(formatDecimalInvertedNumberField(currency.balancePaid)) < 0 ? 'redTextColor' : 'greenTextColor'">{{ formatDecimalInvertedNumberField(currency.balancePaid) }}</div>
        </div>
      </div>
    </div>
    <div v-show="balance?.length === 0 && !isLoading" class="no-items">
      {{ $t("history.text.noData") }}
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading> -->
  </div>
</template>

<script>
import MockService from "@/services/MockService";
export default {
  data() {
    return {
      balance: {},
      isLoading: false,
      isSwitchedCompanies: false
    };
  },
  created() {
    this.isLoading = true;
    MockService.getMutualPaymentsBalance()
      .then((response) => {
        this.balance = response.data;
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  numberColorize(sNumber) {
    return 
  }
};
</script>

<style lang="scss" scoped>
.balance-box {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: relative;
  label {
    color: #c1c1c1;
  }
}
.balance-box-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.company-switch {
  color: #2c3e50;
}

.table-box-desktop {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  line-height: 2rem;
}
.balance-organisations {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table-header {
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 27%;
  label {
    color: #c1c1c1;
  }
  label:after {
    content: ": ";
  }
}

.table-body {
  text-align: end;
}
.balance {
  font-weight: bold;
}
.company-name {
  font-weight: bold;
  color: #000000 !important;
}
.company-name::after {
  content: "" !important;
}
.table-box-mobile {
  width: 100%;
  display: none;
  flex-direction: column;
  gap: 0.5rem;

  .balance-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ededed;

    h1 {
      margin-bottom: 0.5rem;
    }
  }
}

.redTextColor {
  color: #eb5f32;
}

.greenTextColor {
  color: #17873d;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .table-box-desktop {
    display: none;
  }
  .table-box-mobile {
    display: flex;
    .form-item {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .balance-box-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .table-box-desktop {
    display: none;
  }
  .table-box-mobile {
    display: flex;
    .form-item {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
