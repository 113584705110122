<template>
  <section class="custom-tabs">
    <b-tabs size="is-small" :animated="false" @input="handleTabInput">
      <slot></slot>
    </b-tabs>
  </section>
</template>

<script>
export default {
  name: "TheTabs",
  methods: {
    handleTabInput(tabName) {
      this.$emit('input', tabName);
    }
  }
};
</script>

<style lang="scss">
.custom-tabs {
  .b-tabs {
    display: contents;
  }
  .tab-content {
    /*height: calc(100% - 1.5rem); */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 1rem;
    min-height: 10rem;

    .tab-item {
     // overflow-y: auto;
      // overflow-x: hidden;
      flex-shrink: unset;
    }
  }

  .tabs > ul {
    border: none;
    flex-wrap: wrap;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    li {
      border: none;
      a {
        padding: 0 2rem 0 0.5rem;
      }
    }
    li:not(.is-active) a {
      color: #c1c1c1;
    }

    li:not(:first-child) {
      border-left: 1px solid #c1c1c1;

      a {
        padding: 0 2rem;
      }
    }
  }
}
</style>
