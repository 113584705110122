<template>
  <div class="select-control-block">
    <b-dropdown class="user-role" v-model="selectedItem" :mobile-modal="false" size="is-small" aria-role="list" @change="onSorterChange">
      <template #trigger>
        <b-button :label="selectedItem.key ? $t('sorter.label.' + selectedItem.key + '.' + selectedItem.asc) : 'сортировать по'" class="customButton" icon-left="fa-sort" type="is-ghost" icon-right="fa-chevron-down" size="is-small" />
      </template>
      <b-dropdown-item v-for="(item, index) in sortList" :key="index" :value="item" aria-role="listitem">
        <h3>{{ $t("sorter.label." + item.key + "." + item.asc) }}</h3>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    sortList: {
      type: Array,
      default: () => []
    },
    sortView: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedItem: this.$store.state.variants.userVariants[this.sortView]?.sorter ? this.$store.state.variants.userVariants[this.sortView].sorter : {}
    };
  },
  methods: {
    onSorterChange(oValue) {
      this.$emit('change', oValue);
    }
  },
};
</script>

<style lang="scss">
</style>