<template>
  <div class="detailed-form">
    <div class="form-container">
      <div class="wrapping-form-block">
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentType") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderShipmentType) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentCondition") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderShipmentCondition) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.transportType") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderTransportType) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.transportNumber") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderTransportNumber) }}</div>
        </div>
      </div>
      <div class="wrapping-form-block">
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.detail?.orderDepartureDatePlan) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.shipmentDateFact") }}</label>
          <div>{{ formatDateField(shipment?.detail?.orderDepartureDateActual) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.arrivalDatePlan") }}</label>
          <div>{{ formatDateField(shipment?.detail?.orderArrivalDatePlan) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.arrivalDateFact") }}</label>
          <div>{{ formatDateField(shipment?.detail?.orderArrivalDateActual) }}</div>
        </div>
      </div>
      <div class="wrapping-form-block">
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.transportAmount") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderTransportAmount) }}</div>
        </div>
        <div class="form-item wrap-form">
          <label>{{ $t("shipment.label.businessPartner") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderClientName) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("shipment.label.payer") }}</label>
          <div>{{ formatTextField(shipment?.detail?.orderPayer) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object
    }
  }
};
</script>

<style></style>
