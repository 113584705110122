<template>
  <div class="document-item">
    <article class="media">
      <figure class="media-left">
        <p>
          <the-circle-icon icon="fa-file-lines" />
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <a v-on:click="downloadFile(docLink)">
              <strong>
                <u>{{ docName }}</u>
              </strong>
            </a>
            <br />
            <label
              >{{ formattedSize }}
              <span v-show="showDate"> {{ formattedDate }}</span>
            </label>
          </p>
        </div>
      </div>
    </article>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import TheCircleIcon from "@/components/commonLib/TheCircleIcon.vue";
import ApiService from "@/services/ApiService";
//import axios from "axios";
export default {
  components: { TheCircleIcon },
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    documentProps: Object,
    docName: String,
    docLink: String,
    showDate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedSize() {
      const aUnits = ["KB", "MB", "GB"];
      let nDocSize = "0";
      if (this.documentProps.documentSize) {
        nDocSize = this.documentProps.documentSize;
      } else {
        nDocSize = this.documentProps.attachmentDocumentSize;
      }
      nDocSize = nDocSize.replace(/[^0-9]/g, "");
      // documentSize для документа из дерева папок
      // attachmentDocumentSize для документа из переписок
      let dp = -1;
      if (nDocSize < 1024) {
        return nDocSize + "B";
      }

      while (nDocSize > 1024 && dp < 2) {
        nDocSize = nDocSize / 1024;
        dp++;
      }

      return nDocSize.toFixed(2) + " " + aUnits[dp];
    },
    formattedDate() {
      return this.documentProps.documentUploadDate? this.$moment(this.documentProps.documentUploadDate).format('DD.MM.YYYY'): "—";
    }
  },
  methods: {
    downloadFile(sUrl) {
      this.isLoading = true;
      ApiService.get(sUrl, {responseType: 'blob'}).then((response) => {
        let oBlob = new Blob([response.request.response]);
        let fileURL = window.URL.createObjectURL(oBlob);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.docName);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="scss">
.document-item {
  label {
    color: #c1c1c1;
  }
}
</style>
