<template>
  <div class="customPicker">
    <date-picker ref="datepicker" :type="type" prefix-class="xmx" :editable="false" :clearable="false" range v-model="range" :format="formatOfDate" range-separator=" - " :lang="$store.state.user.userLanguage.toLowerCase()" @change="handleSelect">
      <template v-slot:header="{}">
        <div class="vertical-layout">
          <button class="mx-btn mx-btn-text" @click="onSelectPeriod('CM')">{{ $t("filters.datepicker.currentMonth") }}</button>
          <button class="mx-btn mx-btn-text" @click="onSelectPeriod('LM')">{{ $t("filters.datepicker.lastMonth") }}</button>
          <button class="mx-btn mx-btn-text" @click="onSelectPeriod('LHY')">{{ $t("filters.datepicker.lastHalfYear") }}</button>
          <button class="mx-btn mx-btn-text" @click="onSelectPeriod('LY')">{{ $t("filters.datepicker.lastYear") }}</button>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

// function isValidDate(date) {
//   return date instanceof Date && !isNaN(date);
// }
export default {
  components: {
    DatePicker
  },
  props: {
    defaultRange: {
      type: new Array(),
      default: () => [new Date(), new Date()]
    },
    type: {
      type: String,
      default: "month"
    },
    formatOfDate: {
      type: String,
      default: "MM.YYYY"
    }
  },
  data() {
    return {
      innerValue: this.defaultRange,
      range: this.defaultRange
    };
  },
  created() {
    this.handleSelect();
  },
  methods: {
    onSelectPeriod(sParam) {
      let oSelectedRange = [];
      // const oCurrentDate = new Date();
      let sStart = new Date();
      let sEnd = this.$moment();
      if (this.type === "month") {
        sEnd = this.$moment().endOf("month");
      }

      switch (sParam) {
        case "CM":
          sStart = this.$moment().startOf("month");
          break;
        case "LM":
          sStart = this.$moment().subtract(1, "month").startOf("month");
          break;
        case "LHY":
          sStart = this.$moment().subtract(6, "month").startOf("month");
          break;
        case "LY":
          sStart = this.$moment().subtract(1, "year").startOf("month");
          break;
        default:
          break;
      }

      oSelectedRange = [sStart._d, sEnd._d];
      this.innerValue = oSelectedRange;
      this.range = oSelectedRange;
      this.$emit("rangeChange", this.range);
      this.$refs.datepicker.closePopup();
    },

    getClasses(cellDate, currentDates, classes) {
      if (!/disabled|active|not-current-month/.test(classes) && currentDates.length === 2 && cellDate.getTime() > currentDates[0].getTime() && cellDate.getTime() < currentDates[1].getTime()) {
        return "in-range";
      }
      return "";
    },

    handleSelect() {
      this.$emit("rangeChange", this.range);
    }
  }
};
</script>

<style lang="scss">
.customPicker {
  .xmx-datepicker-range {
    width: 200px;
  }
}
.mx-btn:hover {
  border-color: #f26c41 !important;
  color: #f26c41 !important;
}

.xmx-datepicker-body {
  display: flex;
}
</style>
