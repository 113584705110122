<template>
  <div class="payment-history-block">
    <toolbar-panel :isTitleVisible="false" title="" @onSearch="onSearchTable" @onFilter="onFilterTable" :isFilterApplied="isFilterApplied">
      <template v-slot:left>
        <the-select :sortList="defaultSorters" sortView="paymentsHistory" @change="onGetSorterParams" />
      </template>
    </toolbar-panel>
    <b-table :data="payments?.length > 0 ? payments : []" :loading="isLoading" :mobile-cards="true" :sticky-header="false" :paginated="true" :per-page="20" :pagination-simple="true" pagination-size="is-small" class="customPaymentTable" pagination-position="top">
      <b-table-column field="document_id" :label="$t('billings.history.payment.document.number')">
        <template v-slot:header="{ column }">
          <h4 class="bold">{{ column.label }}</h4>
        </template>
        <template v-slot="props">
          <h4>{{ props.row.documentNumber }}</h4>
        </template>
      </b-table-column>
      <b-table-column field="document_date" :label="$t('billings.history.payment.document.date')">
        <template v-slot:header="{ column }">
          <h4 class="bold">{{ column.label }}</h4>
        </template>
        <template v-slot="props">
          <h4>{{ formatDateField(props.row.documentDate) }}</h4>
        </template>
      </b-table-column>

      <b-table-column field="posting_date" :label="$t('billings.history.payment.posting.date')">
        <template v-slot:header="{ column }">
          <h4 class="bold">{{ column.label }}</h4>
        </template>
        <template v-slot="props">
          <h4>
            {{ formatDateField(props.row.documentPostingDate) }}
          </h4>
        </template>
      </b-table-column>

      <b-table-column field="name" :label="$t('billings.history.payment.name')">
        <template v-slot:header="{ column }">
          <h4 class="bold">{{ column.label }}</h4>
        </template>
        <template v-slot="props">
          <h4>{{ props.row.documentName }}</h4>
        </template>
      </b-table-column>

      <b-table-column field="amount" :label="$t('billings.history.payment.amount')" numeric>
        <template v-slot:header="{ column }">
          <h4 class="bold">{{ column.label }}</h4>
        </template>
        <template v-slot="props">
          <h4>{{ formatDecimalNumberField(props.row.documentSum) }} {{ props.row.documentCurrency }}</h4>
        </template>
      </b-table-column>

      <template #empty>
        <h4 class="has-text-centered">{{ $t("billings.history.no.data") }}</h4>
      </template>
    </b-table>
    <the-popup ref="popup" :closable="true" height="25rem">
      <the-filter-dialog @filter="onGetFilteringParams" :defaultSettings="defaultFilterSettings" />
    </the-popup>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import ToolbarPanel from "@/components/commonLib/ToolbarPanel.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import TheFilterDialog from "@/components/commonLib/TheFilterDialog.vue";
import TheSelect from "@/components/commonLib/TheSelect.vue";

export default {
  components: { ToolbarPanel, ThePopup, TheFilterDialog, TheSelect },
  props: {
    bClosedPayments: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      payments: [],
      isFilterApplied: false,
      searchValue: "",
      presetFilters: null,
      presetSorter: this.$store.state.variants?.userVariants?.paymentsHistory?.sorter ? this.$store.state.variants.userVariants.paymentsHistory.sorter : null,
      searchingFields: ["documentName", "documentNumber", "documentCurrency"],
      defaultFilterSettings: {
        filters: [
          {
            name: "documentDate",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "documentPostingDate",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          }
        ],
        sorters: []
      },
      defaultSorters: [
        {
          name: "documentDate",
          active: false,
          key: "documentDate",
          asc: true
        },
        {
          name: "documentPostingDate",
          active: false,
          key: "documentPostingDate",
          asc: true
        },
        {
          name: "documentSum",
          active: false,
          key: "documentSum",
          asc: false
        }
      ]
    };
  },
  mounted() {
    this.getPaymentsList();
  },
  methods: {
    onGetFilteringParams(oFilters) {
      this.$refs.popup.close();
      this.presetFilters = oFilters.filters.length > 0 ? oFilters : null;
      this.getPaymentsList();
      this.isFilterApplied = !!this.presetFilters;
    },
    onSearchTable(oEvent) {
      this.searchValue = oEvent.value.toLowerCase();
      this.getPaymentsList();
    },
    onGetSorterParams(oSorter) {
      this.presetSorter = oSorter;
      this.getPaymentsList();
    },
    getPaymentsList() {
      this.isLoading = true;
      MockService.getMutualPaymentsDocuments()
        .then((oResponse) => {
          let aPayments = oResponse.data.historyDocuments;
          // aPayments = aPayments.historyOpened.concat(aPayments.historyClosed);
          if (this.searchValue) {
            aPayments = this.searchDataByFields(aPayments, this.searchingFields, this.searchValue);
          }
          if (this.presetFilters) {
            aPayments = this.filterAndSortArray(aPayments, this.presetFilters);
          }
          if (this.presetSorter) {
            aPayments = this.sortArray(aPayments, this.presetSorter);
          }

          this.payments = aPayments;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // if (this.bClosedPayments) {
    //   this.payments = aPayments.historyClosed.filter((oPayment) => oPayment.documentName.toLowerCase().indexOf(oEvent.value.toLowerCase()) > -1);
    // } else {
    //   this.payments = aPayments.historyOpened.filter((oPayment) => oPayment.documentName.toLowerCase().indexOf(oEvent.value.toLowerCase()) > -1);
    // }
    onFilterTable() {
      this.$refs.popup.open();
    }
  }
};
</script>

<style lang="scss">
.payment-history-block {
  width: 100%;
  text-align: left;

  .toolbar-panel h1 {
    font-size: 1rem;
  }

  .customPaymentTable {
    //max-height: 90vh;
    .b-table .table th {
      font-weight: 400;
      font-size: 0.8rem;
      color: #c1c1c1;
    }
    .b-table .table td {
      font-size: 0.8rem;
    }

    .pagination {
      .icon svg {
        height: 1rem;
      }
    }
    small {
      font-size: 0.875rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .customPaymentTable.b-table .table-wrapper.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
    content: attr(data-label);
    font-weight: 700;
    font-size: 0.725rem;
    padding-right: 0.5em;
    text-align: left;
  }
}
</style>
