<template>
  <div id="app">
    <nav-bar v-if="isLoggedIn" />
    <div id="pageContentBlock" class="page-сontent-block" style="width: 100%">
      <page-header v-if="isLoggedIn" />
      <div class="view">
        <router-view />
      </div>
    </div>
  </div>
</template>


<script>
import PageHeader from "@/components/commonLib/header/PageHeader";
import MockService from "@/services/MockService";
import NavBar from "@/components/commonLib/nav/NavBar.vue";
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import {version} from '../package';

export default {
  computed: {
    currentRoute() {
      return this.$t(this.$route.name);
    },
    ...mapGetters({
      isLoggedIn: "secure/isLoggedIn"
    })
  },
  created() {
    const bIsLoggedIn = this.isLoggedIn;
    window.onstorage = (storageEvent) => {
      console.log(window.localStorage.getItem("token"));
      if (storageEvent.newValue === null) {
        this.logoutCall(bIsLoggedIn);
        location.reload();
      } else if (storageEvent.newValue !== storageEvent.oldValue) {
        location.reload();
      }
    }
    if (localStorage.getItem("isAdminPreview")) {
      this.$router.push({ path: "/", replace: true });
    }
    
    EventBus.$on("logout", () => {
      this.logoutCall(bIsLoggedIn);
    });
    if (!bIsLoggedIn) {
      return;
    }
    this.updateHeader();
    MockService.getUser()
      .then((oResponse) => {
        this.changeLocale(oResponse.data.userLanguage.toLowerCase());
        localStorage.setItem("language", oResponse.data.userLanguage.toLowerCase());
        this.$store.state.user = oResponse.data;
        this.$store.state.backendVersion = oResponse.headers.get("x-rest-service-version");
        this.$store.state.frontendVersion = version;
        this.updateHeader();
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage);
      });
  },
  watch: {
    $route() {
      this.updateHeader();
    }
  },
  methods: {
    logoutCall(bIsLoggedIn) {
      this.$store.dispatch("secure/logout").then(() => {
        if (bIsLoggedIn) {
          this.$buefy.toast.open({
            duration: 2000,
            message: "Сессия истекла. Авторизуйтесь заново",
            type: "is-warning",
            position: "is-top"
          });
        }
        if (this.$router.history.current.name !== "login") {
          this.$router.push({ path: "/login", replace: true });
        }
      });
    }
  },
  components: {
    PageHeader,
    NavBar
  }
};
</script>

<style lang="scss">
.page-сontent-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>

