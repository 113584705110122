<template>
  <div class="shipment-docs">
    <vue-tree-list :model="data" v-bind:default-expanded="false" v-bind:dragDisabled="true" v-show="documents?.length">
      <template v-slot:leafNameDisplay="slotProps">
        <h4 v-if="!slotProps.model.isLeaf">
          {{ slotProps.model.name }}
        </h4>
        <document-item v-else :documentProps="slotProps.model.optParam" :docName="slotProps.model.name" :docLink="slotProps.model.optParam.documentDownloadLink" />
      </template>
      <span class="icon" slot="leafNodeIcon"></span>
    </vue-tree-list>
    <div v-show="!documents?.length" class="no-items">
      {{ $t("history.text.noData") }}
    </div>
  </div>
</template>

<script>
import DocumentItem from "@/components/commonLib/DocumentItem.vue";

import { VueTreeList, Tree, TreeNode } from "vue-tree-list";

export default {
  components: {
    DocumentItem,
    VueTreeList
  },
  props: {
    documents: []
  },
  data() {
    return {
      data: new Tree([])
    };
  },
  watch: {
    documents() {
      this.createTreeOfDocs(this.documents);
    }
  },
  methods: {
    createTreeOfDocs(oDocuments) {
      let oBasicParams = {
        dragDisabled: true,
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true
      };

      let oNewDocTree = [];

      (oDocuments || []).forEach((oDocTag, iTagIndex) => {
        let oParentNode = Object.assign({}, oBasicParams, {
          name: oDocTag.documentTag,
          id: iTagIndex,
          pid: 0,
          optParam: {},
          children: []
        });

        oDocTag.documents.forEach((oChild, iChildIndex) => {
          oParentNode.children.push(
            Object.assign({}, oBasicParams, {
              name: oChild.documentName,
              id: `${iTagIndex}_${iChildIndex}`,
              pid: iTagIndex,
              optParam: oChild,
              isLeaf: true
            })
          );
        });
        oNewDocTree.push(oParentNode);
      });
      this.data = new Tree(oNewDocTree);
    }
  }
};
</script>

<style lang="scss">
.shipment-docs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .docs-container {
    margin-top: 0.5rem;
    gap: 0.5rem;
  }
  .docs-container > div {
    margin-top: 0.5rem;
  }
}
.vtl {
  .vtl-icon:hover {
    color: #f26c41;
  }
  .vtl-leaf-node .vtl-drag-disabled > span {
    display: none;
  }
}
</style>
