<template>
  <div class="request-detail">
    <div class="chat-toolbar">
      <div class="collaps-chat-info">
        <the-collapsing-panel :title="$t('title.detail')" class="showOnDesktop" :isOpened="false">
          <div class="chat-info">
            <div class="form-item">
              <label>{{ $t("request.detail.internalId") }}</label>
              <div>{{ formatTextField(request?.requestHeader?.requestOrderIternalId) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("request.detail.shipmentId") }}</label>
              <div class="bold">{{ formatTextField(request?.requestHeader?.requestOrderNumber) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("request.detail.direction") }}</label>
              <div>{{ request?.requestHeader?.requestOrderFrom }} - {{ request?.requestHeader?.requestOrderTo }}</div>
            </div>
          </div>
        </the-collapsing-panel>
        <div class="hideOnMobile chat-info">
          <div class="form-item">
            <label>{{ $t("request.detail.internalId") }}</label>
            <div class="bold">{{ formatTextField(request?.requestHeader?.requestOrderIternalId) }}</div>
          </div>
          <div class="form-item">
            <label>{{ $t("request.detail.shipmentId") }}</label>
            <div>{{ formatTextField(request?.requestHeader?.requestOrderNumber) }}</div>
          </div>          
          <div class="form-item">
            <label>{{ $t("request.detail.direction") }}</label>
            <div>{{ request?.requestHeader?.requestOrderFrom }} - {{ request?.requestHeader?.requestOrderTo }}</div>
          </div>
        </div>
      </div>
      <div class="customInput horizontal-layout">
        <b-button size="is-small" type="is-primary" style="width: 10rem" @click="onCloseRequestClick" v-show="request?.requestHeader?.requestStatus !== 'READY'">{{ $t("btn.close.request") }}</b-button>
        <b-input
          :placeholder="$t('search')"
          type="search"
          size="is-small"
          icon="search"
          icon-clickable
          v-model="searchValue"
          @input="onSearchMessageClick"
          @icon-click="onSearchMessageClick"
          @change.native="onSearchMessageClick"
          class="margin-left"
        />
      </div>
    </div>
    <div class="chat">
      <div class="chat-aria">
        <request-chat :messages="messages" />
      </div>
      <div class="chat-option-panel">
        <button @click="onAttachFile">
          <font-awesome-icon icon="fa-solid fa-paperclip" />
        </button>
        <b-input type="text" size="is-small" v-model="sMessageToSend" v-on:keyup.native.enter="onSendMessage" :placeholder="$t('filters.placeholder.enter.text')" />
        <button @click="onSendMessage($event, true)">
          <font-awesome-icon icon="fa-solid fa-paper-plane" />
        </button>
      </div>
    </div>
    <the-popup ref="popup" :closable="isAttachDialogClose" height="15rem">
      <the-attach-doc-dialog @closeAttachDocDialog="closeAttachDocDialog" @banCloseDialog="banCloseDialog"></the-attach-doc-dialog>
    </the-popup>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import TheCollapsingPanel from "@/components/commonLib/TheCollapsingPanel.vue";
import RequestChat from "@/components/commonLib/requests/RequestChat.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import TheAttachDocDialog from "@/components/commonLib/requests/TheAttachDocDialog.vue";

export default {
  components: { TheCollapsingPanel, RequestChat, TheAttachDocDialog, ThePopup },
  data() {
    return {
      request: {},
      searchValue: "",
      sMessageSearch: "",
      sMessageToSend: "",
      isLoading: false,
      isAttachDialogClose: true
    };
  },
  mounted() {
    this.getRequestInfo();
  },
  computed: {
    messages: {
      get() {
        if (this.sMessageSearch) {
          return this.request.requestBody.filter((oMessage) => oMessage.messageText.toLowerCase().indexOf(this.sMessageSearch.toLowerCase()) > -1);
        } else {
          return this.request.requestBody;
        }
      }
    }
  },

  methods: {
    getRequestInfo() {
      const sId = this.$route.params.requestId;
      this.isLoading = true;
      MockService.getRequest(sId)
        .then((oResponse) => {
          this.request = oResponse.data;
          this.changeRequestTitle();
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearchMessageClick() {
      this.sMessageSearch = this.searchValue;
      // if (this.searchValue) {
      //   this.messages = this.request.messages.filter(
      //     (oMessage) =>
      //       oMessage.content
      //         .toLowerCase()
      //         .indexOf(this.searchValue.toLowerCase()) > -1
      //   );
      // } else {
      //   this.messages = this.request.messages;
      // }
      // this.messages;
    },
    onSendMessage(oEvent, bSendButtonPressed) {
      if (!bSendButtonPressed && this.isMobile()) {
        return;
      }
      this.searchValue = "";
      this.sMessageSearch = "";
      //временный объект
      const oMessageContent = {
        messageText: this.sMessageToSend,
        messageCreation: {
          actionDate: this.$moment().toISOString(),
          actionAuthor: this.$store.state.user.userFullname
        },
        messageAttachments: [],
        messageSeen: true,
        bIsNew: true
      };

      this.request.requestBody.push(oMessageContent);
      let oRequest = this.request;
      this.request = oRequest;
      this.sMessageToSend = "";

      MockService.sendMessage(this.request.requestHeader.requestId, oMessageContent)
        .then(() => {
          this.getRequestInfo();
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        });
    },
    onAttachFile() {
      this.$refs.popup.open();
    },
    closeAttachDocDialog() {
      this.$refs.popup.close();
      this.isAttachDialogClose = true;
      this.getRequestInfo();
    },
    changeRequestTitle() {
      let sTitle = this.$t("title." + this.$route.name, [this.request.requestHeader.requestTitle]);
      this.$store.commit("setHeaderParams", {
        title: sTitle,
        isNavBack: true
      });
    },
    onCloseRequestClick() {
      MockService.closeRequest(this.request.requestHeader.requestId)
        .then((oResponse) => {
          this.successMessage(this.$t("success.closedRequest"));
          this.$router.go(-1);
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        });
    },
    banCloseDialog(){
      this.isAttachDialogClose = false;
    }
  }
};
</script>

<style lang="scss">
.chat-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
}
.collaps-chat-info {
  padding: 0.2rem
}
.collaps-chat-info > .custom-panel > .collapse > .collapse-content > .card-content {
    position: absolute;
    z-index: 1 !important;
    background-color: white;
    margin-top: -0.4rem;
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }

.chat-info {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.request-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
}

.chat {
  height: -webkit-fill-available;
  height: -moz-available;
  height: calc(100vh - 10rem);
  //min-height: 100%;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .chat-aria {
    background-color: #c1c1c130;
    border-radius: 10px;
    height: -webkit-fill-available;
    height: -moz-available;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .chat-option-panel {
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #c1c1c130;

    & > .control {
      width: 100% !important;
      min-width: 65% !important;
    }

    input,
    input:focus,
    input:active,
    input:focus-visible {
      box-shadow: none;
      width: 100% !important;
      border: 0 !important;
      font-size: 0.825rem;
      color: #2c3e50;
      padding: 0;
    }

    input:focus-visible {
      outline: 0 !important;
    }

    svg {
      color: #c1c1c1;
    }
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .chat-toolbar > div {
    width: 100%;
    justify-content: flex-end;
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px)  {
  .chat-toolbar > div {
    width: 100%;
    justify-content: flex-end;
  }
}
</style>
