<template>
  <button class="base-tile" @click="onNavTo">
    <div class="description">
      {{ description }}
    </div>
    <div class="type-block">
      <font-awesome-icon :icon="icon" />
      <span v-show="showNumber">{{ number }}</span>
    </div>
    <h4 class="annotaion">
      {{ annotation }}
    </h4>
  </button>
</template>

<script>
export default {
  name: "BaseTile",
  props: {
    icon: {
      type: String,
      default: "fa-solid fa-house"
    },
    number: {
      type: [String, Number]
    },
    showNumber: {
      type: Boolean,
      default: false
    },
    numberColor: {
      type: String,
      default: "primary"
    },
    description: {
      type: String
    },
    annotation: {
      type: String
    },
    routeName: {
      type: String
    }
  },
  methods: {
    onNavTo() {
      this.$router.push(this.routeName);
    }
  }
};
</script>

<style lang="scss" scoped>
$primaryColor: #2c3e50;
.base-tile {
  width: 48%;
  height: 15%;
  min-width: 8rem;
  max-width: 12rem;
  min-height: 8rem;
  padding: 1.2rem;
  background: #ffffff;
  color: $primaryColor;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h4 {
    color: rgba(0, 0, 0, 0.4);
    text-align: start;
  }
}

.base-tile .description {
  font-size: 0.85rem;
  text-align: start;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.base-tile .type-block {
  display: flex;
  flex-direction: row;

  align-items: baseline;
  padding: 0px;
  gap: 15px;

  svg {
    font-size: 1.5rem;
  }

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (max-width: 600px) {
  .base-tile {
    padding: 0.5rem;
    .description {
      font-size: 0.9rem;
    }
  }
}
</style>
