<template>
  <b-menu-list>
    <router-link to="/">
      <div>
        <font-awesome-icon icon="fa-solid fa-house" />
      </div>
      <h2>{{ $t("title.home") }}</h2>
    </router-link>
    <router-link to="/shipment">
      <div>
        <font-awesome-icon icon="fa-solid fa-clipboard-list" />
      </div>
      <h2>{{ $t("title.shipment") }}</h2>
    </router-link>
    <router-link to="/requests">
      <div>
        <font-awesome-icon icon="fa-solid fa-comments" class="fasIcon" />
      </div>
      <h2>{{ $t("title.requests") }}</h2>
    </router-link>
    <router-link to="/billings">
      <div>
        <font-awesome-icon
          icon="fa-solid fa-file-invoice-dollar"
          class="fasIcon"
        />
      </div>
      <h2>{{ $t("title.billings") }}</h2>
    </router-link>
    <router-link to="/reports">
      <div>
        <font-awesome-icon icon="fa-solid fa-chart-column" class="fasIcon" />
      </div>
      <h2>{{ $t("title.reports") }}</h2>
    </router-link>
    <router-link to="/settings">
      <div>
        <font-awesome-icon icon="fa-solid fa-gear" />
      </div>
      <h2>{{ $t("title.settings") }}</h2>
    </router-link>
  </b-menu-list>
</template>

<script>
export default {
  name: "RouteLinks"
};
</script>

<style>
</style>