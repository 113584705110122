<template>
  <div class="message-item" :class="isUserMessage ? 'revert-message' : ''">
    <div :style="{ visibility: isLastFlag ? 'visible' : 'hidden' }">
      <div v-if="!isUserMessage" class="circle-icon">{{ initials }}</div>
      <the-circle-icon v-else />
    </div>
    <div class="message-block" :class="isUserMessage ? 'flex-end' : ''">
      <h4 v-show="isFirstFlag" class="block-author">
        {{ isUserMessage ? "Вы" : messageAuthor }}
      </h4>
      <div>
        <div class="unread-message" v-show="isNewMessage">
          <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
        </div>
        <div class="message-content">
          <div v-for="doc in attachments" :key="doc.attachmentDocumentId" style="width: 100%" class="margin-bottom">
            <document-item :showDate="false" :documentProps="doc" :docName="doc.attachmentDocumentName"  :docLink="doc.attachmentDocumentLink"/>
          </div>
          <div class="message-text">{{ messageContent }}</div>
          <label>{{ messageDate }}</label>
        </div>
        <div class="unread-message" v-show="isUnread">
          <font-awesome-icon icon="fa-solid fa-circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentItem from "@/components/commonLib/DocumentItem.vue";
import TheCircleIcon from "@/components/commonLib/TheCircleIcon.vue";
export default {
  components: { TheCircleIcon, DocumentItem },
  props: {
    isUserMessage: {
      type: Boolean,
      default: true,
    },
    isHasAttachment: {
      type: Boolean,
      default: false,
    },
    messageContent: {
      type: String,
    },
    attachments: {
      type: new Array(),
    },
    messageDate: {
      type: String,
    },
    isUnread: {
      type: Boolean,
      default: false,
    },
    messageAuthor: {
      type: String,
    },
    isFirstFlag: {
      type: Boolean,
      default: true,
    },
    isLastFlag: {
      type: Boolean,
      default: true,
    },
    isNewMessage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    initials() {
      let aSplittedName = this.messageAuthor.split(" ");
      let aInitials = [];
      aSplittedName.forEach((sName) => {
        aInitials.push(sName[0]?.toUpperCase());
      });
      return aInitials.join("");
    },
  },
};
</script>

<style lang="scss">
.message-item {
  font-size: 0.825rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-end;

  .circle-icon {
    background-color: #2c3e50;
    color: #fff;
  }
  .message-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 70%;
    max-width: 40rem;

    .block-author {
      margin-top: 0.5rem;
    }
  }
  .message-block.flex-end {
    align-items: flex-end;
  }
  .message-block > div {
    display: flex;
    flex-direction: row;
  }

  .message-content {
    padding: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
    margin-top: 0.2rem;

    .message-text {
      width: 100%;
      text-align: left;
    }

    label {
      color: #c1c1c1;
      font-size: 0.725rem;
    }
  }
}

.message-item.revert-message {
  flex-direction: row-reverse;
}
</style>
