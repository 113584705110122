<template>
  <div class="toolbar-panel horizontal-layout">
    <div class="toolbar-panel-left">
      <font-awesome-icon
        :icon="icon ? icon : 'fa-solid fa-search'"
        v-show="Boolean(icon)"
      />
      <h2 class="margin-left" v-show="Boolean(title)">{{ title }}</h2>
      <slot name="left"></slot>
    </div>
    <div class="toolbar-panel-right">
      <slot name="right-begin"></slot>
      <b-input
        v-show="isSearchVisible"
        :placeholder="$t('search')"
        type="search"
        size="is-small"
        class="margin-left"
        icon="search"
        icon-clickable
        v-model="searchValue"
        @input="onSearchClick"
        @icon-click="onSearchClick"
        >
      </b-input>
      <b-button
        v-show="isFilterVisible"
        :type="isFilterApplied? 'is-primary is-small' : 'is-light is-small'"
        icon-right="fa-filter"
        class="margin-left"
        outlined
        @click="$emit('onFilter')"
      />

      <b-button
        v-show="isExportVisible"
        type="is-primary is-small"
        icon-right="download"
        class="margin-left"
        @click="$emit('onExport')"
      />
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolbarPanel",
  props: {
    title: {
      type: String,
      default: "",
    },
    isTitleVisible: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    isFilterVisible: {
      type: Boolean,
      default: false,
    },
    isExportVisible: {
      type: Boolean,
      default: false,
    },
    isSearchVisible: {
      type: Boolean,
      default: true,
    },
    isFilterApplied: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    onSearchClick() {
      this.$emit("onSearch", { value: this.searchValue });
    },
  },
};
</script>

<style lang="scss">
.toolbar-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolbar-panel > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}

.toolbar-panel > .toolbar-panel-right {
  justify-content: flex-end;
  display: flex;

  .icon svg {
    height: 1rem;
  }
}

.toolbar-panel .control.has-icons-left .icon svg {
  height: 1em;
}

.control.has-icons-left .icon {
  z-index: 0 !important;
}

@media screen and (max-width: 600px) and (orientation: portrait) {  

  #pageContentBlock > div.view > div > div > div.admin-toolbar-user-filters > .toolbar-panel > div.toolbar-panel-right {
    display: block;
  }
}
</style>
