<template>
  <div class="bar-chart">
    <div class="horizontal-layout margin-top-large"></div>
    <div style="display: flex; align-items: center">
      <div style="display: inline-block; width: 100%;">
        <Pie :options="chartOptions" :data="chartData" id="paymentPeriodAmountID" :height="isMobile() ? 500 : 300" />
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";
import MockService from "@/services/MockService";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);
ChartJS.defaults.font.family = "Montserrat";
export default {
  components: {
    Pie
  },
  props: {
    reportData: {
      type: new Array(),
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            onClick: (e) => {
              return;
            },
            position: this.isMobile() ? "top" : "right",
            labels: {
              boxWidth: 20,
              padding: 5
            }
          },
          dataLabels: {
            display: true,
            formatter: (value) => {
              return value + "%";
            }
          }
        }
      }
    };
  },
  watch: {
    reportData() {
      let aColors = this.getColorPalette(this.reportData.length);

      let oChartData = JSON.parse(JSON.stringify(this.chartData));
      oChartData.labels = this.reportData.map((oCountry) => this.cutCountryName(oCountry.countryName));
      oChartData.datasets = [
        {
          backgroundColor: aColors,
          data: this.reportData.map((oCountry) => oCountry.ordersQuantity)
        }
      ];

      this.chartData = oChartData;
    }
  },
  methods: {
    cutCountryName: (sCountry) => {
      if (sCountry.length < 28) {
        return sCountry;
      } else {
        return sCountry.slice(0, 25) + "...";
      }
    }
  }
};
</script>

<style>
</style>
