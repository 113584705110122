<template>
  <div class="bar-chart">
    <div style="display: inline-block; width: 100%">
      <Bar :options="chartOptions" :data="chartData" id="shipmentsUnitedReportV2ID" height="400" />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import MockService from "@/services/MockService";

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale);
ChartJS.defaults.font.family = "Montserrat";

export default {
  components: {
    Bar
  },
  props: {
    dateRange: Object,
    reportData: {
      type: new Array(),
      default: () => []
    },
    isSwitchedCopmleted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            onClick: (e) => {
              return;
            },
            position: "top",
            labels: {
              boxWidth: 20
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: this.$t("report.amount")
            },
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                if (value % 1 === 0) {
                  return value;
                }
              }
            }
          }
        }
      }
    };
  },
  watch: {
    isSwitchedCopmleted() {
      this.createdUnitedReport();
    },
    reportData() {
      this.createdUnitedReport();
    }
  },
  methods: {
    createdUnitedReport() {
      let oFinishedDataSet = {
        label: this.$t("report.shipments.finished"),
        backgroundColor: "#F26C41",
        maxBarThickness: 25,
        data: []
      };
      let oAllDataSet = {
        label: this.$t("report.shipments.all"),
        backgroundColor: "#2c3e50",
        maxBarThickness: 25,
        data: []
      };
      let aLabel = [];
      
      if (this.isSwitchedCopmleted) {
        this.reportData.forEach((oElement) => {
        oFinishedDataSet.data.push(oElement.finished);
        aLabel.push(this.$moment(oElement.date).format("MMM YY"));
      });
      } else {
        this.reportData.forEach((oElement) => {
        oAllDataSet.data.push(oElement.all);
        aLabel.push(this.$moment(oElement.date).format("MMM YY"));
      });}

      let oChartData = JSON.parse(JSON.stringify(this.chartData));
      oChartData.labels = aLabel;
      oChartData.datasets = this.isSwitchedCopmleted ? [oFinishedDataSet] : [oAllDataSet];

      this.chartData = oChartData;
    }
  }
};
</script>

<style>
.bar-chart {
  overflow: auto;
}
</style>
