<template>
  <div class="shipment-header">
    <div class="mobile-nav-header">
      <div class="horizontal-layout align-center">
        <b-button type="is-primary is-small is-rounded" icon-right="fa-arrow-left" class="customButton" inverted
          @click="navBack" />
        <h3 class="margin-left">{{ shipment?.general?.orderFrom }} - {{ shipment?.general?.orderTo }}</h3>
      </div>
      <div class="horizontal-layout">
        <!-- <b-button type="is-primary is-small" icon-right="fa-download" class="margin-left customButton" @click="navBack" /> -->
      </div>
    </div>
    <div v-show="bShowRate">
      <the-rate @rateChanged="rateChanged" :rating="rate" v-show="bShowRate" />
      <the-popup ref="popup" :closable="true" height="21.5rem" @closeBtnPress="cancelRatingDialog"
        emitNameOnCloseEvent="closeBtnPress">
        <shipment-rate-dialog :rate="rate" :shipmentId="this.$route.params.shipmentId" @closePopup="closeRatingDialog" />
      </the-popup>
    </div>
  </div>
</template>

<script>
import MockService from "@/services/MockService.js";
import TheRate from "@/components/commonLib/TheRate.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import ShipmentRateDialog from "@/components/shipment/ShipmentRateDialog.vue";
import TheMobileSubHeader from "../commonLib/TheMobileSubHeader.vue";
export default {
  components: {
    TheRate,
    ThePopup,
    ShipmentRateDialog
  },
  props: {
    shipment: {
      type: Object
    }
  },
  data() {
    return {
      rate: 0,
      bIsNotRated: true
    };
  },
  computed: {
    bShowRate() {
      return this.shipment?.general?.orderActualStatus?.statusId === "READY" && !this.shipment.general.orderRating && this.bIsNotRated;
    }
  },
  methods: {
    navBack() {
      this.$router.go(-1);
    },
    rateChanged(value) {
      this.rate = value;
      this.$refs.popup.open();
    },
    cancelRatingDialog() {
      this.rate = 0;
    },
    closeRatingDialog() {
      this.$refs.popup.close();
      this.bIsNotRated = false;
    }
  }
};
</script>

<style lang="scss">
.shipment-header {
  margin: 0.25rem 0;
  margin-bottom: 0.5rem;
}

.mobile-nav-header {
  display: none;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .mobile-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .shipment-header {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .mobile-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .shipment-header {
    margin-bottom: 0.25rem;
  }
}
</style>
