
<template>
  <div>
    <div class="client-balance-block">
      <div class="overflow-table">
        <table class="balance-table-container" id="clientTable">
          <thead class="table-thead">
            <tr class="header-block">
              <th colspan="1" rowspan="2" class="header_block-th">{{ $t("report.balance.agreements") }}</th>
              <th colspan="1" rowspan="2" class="header_block-value_th">
                {{ $t("report.balance.rest") }} <br />
                {{ $t("report.balance.restOn", [$moment(dateRange.startDate).format("DD.MM.YYYY")]) }}
              </th>
              <th colspan="2" class="header_block-value_th mergeColumn">
                <div class="subheader-header-text">{{ $t("report.balance.turnover") }}</div>
              </th>
              <th colspan="1" rowspan="2" class="header_block-value_th">
                {{ $t("report.balance.rest") }} <br />
                {{ $t("report.balance.restOn", [$moment(dateRange.endDate).format("DD.MM.YYYY")]) }}
              </th>
            </tr>
            <tr>
              <th scope="col" class="header_block-value_th">{{ $t("report.balance.debit") }}</th>
              <th scope="col" class="header_block-value_th">{{ $t("report.balance.credit") }}</th>
            </tr>
          </thead>
          <tfoot>
          <!-- <tr>
              <td scope="row" class="total-row-th">{{ $t("report.balance.total") }}</td>
              <td class="total-row-th end-align">
                {{ formatDecimalNumberField(balanceReport.total.balanceStart) }}
              </td>
              <td class="total-row-th end-align">
                {{ formatDecimalNumberField(balanceReport.total.debet) }}
              </td>
              <td class="total-row-th end-align">
                {{ formatDecimalNumberField(balanceReport.total.credit) }}
              </td>
              <td class="total-row-th end-align">
                {{ formatDecimalNumberField(balanceReport.total.balanceEnd) }}
              </td>
              </tr> -->
          </tfoot>
          <tbody v-for="balance in balanceReport.data" :key="balance.currency">
            <tr>
              <th scope="row">{{ shipmentTitle(balance.contractNumber) }} {{ $t("report.balance.contractCurrency",
                [balance.currency]) }}</th>
              <td class="end-align" :class="Number(formatDecimalInvertedNumberField(balance.balanceStart)) < 0 ? 'redTextColor' : 'greenTextColor'">
                {{ formatDecimalInvertedNumberField(balance.balanceStart) }}
              </td>
              <td class="end-align">
                {{ formatDecimalNumberField(balance.debet) }}
              </td>
              <td class="end-align">
                {{ formatDecimalNumberField(balance.credit) }}
              </td>
              <td class="end-align" :class="Number(formatDecimalInvertedNumberField(balance.balanceEnd)) < 0 ? 'redTextColor' : 'greenTextColor'">
                {{ formatDecimalInvertedNumberField(balance.balanceEnd) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
export default {
  components: {},
  props: {
    dateRange: Object,
    reportData: {
      type: Object,
      default: () => {
        return {
          data: [],
          total: {}
        };
      }
    }
  },
  data() {
    return {
      report: {
        startDate: new Date(2022, 0, 1),
        endDate: new Date(2022, 11, 31)
      }
    };
  },
  computed: {
    balanceReport() {
      return this.reportData;
    }
  },
  methods: {
    shipmentTitle(sOrder, sInternalId) {
      if (sOrder) {
        let sTitle = sOrder;
        sTitle += sInternalId ? ` (${sInternalId})` : "";
        return sTitle;
      } else {
        return this.$t("report.balance.undefinedOrder")
      }
    }
  }
};
</script>


<style lang="scss">
.client-balance-block {
  .overflow-table {
    overflow-x: auto;
    margin-top: 1rem;
  }

  table {
    font-size: 0.725rem;
    width: 100%;
    min-width: 25rem;

    .table-thead {
      background-color: #2c3e50;

      .header_block-th {
        vertical-align: middle;
        color: white;
      }

      .header_block-value_th {
        text-align: end;
        vertical-align: middle;
        color: white;
      }

      .mergeColumn {
        padding-left: 0.5rem;
      }

      .subheader-header-text {
        border-bottom: 1px solid #ffffff35;
        margin-left: 1rem;
        padding-bottom: 0.5rem;
      }
    }

    .total-row-th {
      background-color: #f26c41;
      color: white;
    }

    .end-align {
      text-align: end;
    }
  }

  table th,
  table td {
    padding: 0.5rem 0.2rem;
    padding-top: 0.5rem;
  }

  tbody tr {
    border-bottom: 1px solid #00000015;
  }

  .subheader-block {
    border-bottom: 2px solid #00000015;
  }
}

.redTextColor {
  color: #eb5f32;
}

.greenTextColor {
  color: #17873d;
}
</style>
