import axios from "axios";
import {
    EventBus
} from '@/event-bus';


let bIsRefreshing = false; // флаг ожидания выполнения запроса на обновление токена
let aWaitingRequests = [];

/**
 * Вызов функций из списка ожидания
 * @param {string} sToken - новый refresh токен
 */
function onRefreshed(sToken) {
    aWaitingRequests.map(oFunction => oFunction(sToken));
}

/**
 * Запись запроса в лист ожидания
 * @param {Function} oFunction - функция обработки запроса 
 */
function putToWaitList(oFunction) {
    aWaitingRequests.push(oFunction);
}

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: false
});


/**
 * Перехват запросов
 * Во время исполнения перехвата добавляем к запросу нужные хедеры
 */

apiClient.interceptors.request.use(function (config) {
    config.headers = {
        Accept: '*/*',
        Authorization: localStorage.getItem('token'),
        'Is-Admin-Preview': localStorage.getItem('isAdminPreview')
    };
    return config;
}, function (error) {
    return Promise.reject(error);
});

/**
 * Перехват ответов запроса
 */
apiClient.interceptors.response.use(response => {
    return response;
}, error => {
    //получаем необходимые конфиги из ошибки (запрос и код ошибки)
    const { config, response: { status } } = error;
    const originalRequest = config;

    if (status === 401) {
        if (!bIsRefreshing) {
            bIsRefreshing = true;
            //запрашиваем обновление токена
            fnRefreshToken().then((sToken) => {
                bIsRefreshing = false;
                //после успешного обновления токена вызываем функции, попавшие в лист ожидания
                onRefreshed(sToken);
                //очищаем лист ожидания
                aWaitingRequests = [];
            }).catch(() => {
                bIsRefreshing = false;
                aWaitingRequests = [];
                EventBus.$emit('logout');
            });
        }
        return new Promise(resolve => {
            //пушим невыполненный запрос в лист ожидания
            putToWaitList((sToken) => {
                originalRequest.headers['Authorization'] = sToken;
                resolve(apiClient(originalRequest));
            });
        });
    } else if (status === 403) {
        //если статус 403 то переходим на страницу логина
        EventBus.$emit('logout');
    } else {
        return Promise.reject(error);
    }
});

/**
 * Вызов обновления токенов
 * 
 * @returns {Promise}
 */
const fnRefreshToken = () => {
    const sRefreshToken = localStorage.getItem('refreshToken');
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_AUTH}account/refreshToken`, JSON.stringify({
            refreshToken: sRefreshToken
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                const encodedToken = 'Bearer ' + response.data.accessToken;
                const refreshToken = response.data.refreshToken;
                localStorage.setItem('token', encodedToken);
                localStorage.setItem('refreshToken', refreshToken);
                apiClient.defaults.headers.Authorization = encodedToken;
                resolve(encodedToken);
            })
            .catch(function (error) {
                reject(error);
            });
    });
};

export default apiClient;
