<template>
  <div class="attach-doc-dialog">
    <div class="attach-block">
      <div class="attach-header">
        <h1 class="attach-header-label">{{ $t("attachDoc.title.upload") }}</h1>
      </div>
      <div class="form-attach-doc">
        <div class="form-item">
          <div class="required">
            <label class="upload-label">{{ $t("attachDoc.label.upload.file") }}</label>
          </div>
          <b-field class="file is-primary">
            <b-upload v-model="file" class="file-label" size="is-small">
              <span class="file-cta">
                <b-icon class="file-icon customIcon" icon="fa-upload"></b-icon>
                <span v-bind:class="{ 'file-name': file.name }">{{ file.name || $t("attachDoc.label.clickToUpload") }}</span>
              </span>
            </b-upload>
          </b-field>
        </div>
        <div class="form-item">
          <div class="required">
            <label class="upload-label">{{ $t("attachDoc.label.upload.folder") }}</label>
          </div>
          <b-dropdown aria-role="list" :mobile-modal="false" v-model="currentMenu" style="width: min-content">
            <template #trigger>
              <b-button :label="currentMenu?.tagName ? currentMenu?.tagName : $t('btn.folder')" class="customButton" type="is-primary is-small" icon-right="fa-chevron-down" />
            </template>
            <b-dropdown-item v-for="folder in docTags" :key="folder.tagId" :value="folder" aria-role="listitem">
              {{ folder.tagName }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="attach-footer">
      <b-button class="send-btn" :label="$t('btn.send')" type="is-primary is-small" v-on:click="sendFile" />
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
export default {
  data() {
    return {
      currentMenu: {
        tagId: null,
        tagName: null
      },
      file: [],
      docTags: [],
      isLoading: false
    };
  },
  created() {
    MockService.getDocumentTags()
      .then((oResponse) => {
        this.docTags = oResponse.data.tags;
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage);
      });
  },
  methods: {
    selectDocTag(documentTag) {
      this.currentMenu = documentTag;
    },
    sendFile() {
      const sId = this.$route.params.requestId;
      if (!this.file.name) {
        return this.errorMessage(this.$t("attachDoc.warning.file"));
      }
      if (!this.currentMenu.tagId) {
        return this.errorMessage(this.$t("attachDoc.warning.folder"));
      }
      if (this.file.size > 2097152) {
        return this.errorMessage(this.$("attachDoc.warning.size"));
      }
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("tag", this.currentMenu.tagId);
      this.isLoading = true;
      this.$emit("banCloseDialog");
      MockService.sendAttachment(sId, formData)
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("closeAttachDocDialog");
          this.currentMenu = {
            tagId: null,
            tagName: null
          };
          this.file = [];
        });
    }
  }
};
</script>

<style lang="scss">
.attach-doc-dialog {
  background-color: #ffffff;
  width: 20rem;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .attach-block {
    display: flex;
    flex-direction: column;
  }

  .attach-header-label {
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-attach-doc {
    width: 100%;

    display: flex;
    flex-direction: column;

    .form-item {
      width: 100%;

      .required:after {
        content: " *";
        color: red;
      }
      .upload-label:after {
        content: ":";
      }

      div {
        width: 100%;
      }

      .file-label {
        font-size: 0.75rem;

        .file-cta {
          border-radius: 2px;
          text-overflow: ellipsis;
        }

        .file-name {
          white-space: nowrap; /* Текст не переносится */
          overflow: hidden; /* Обрезаем всё за пределами блока */
          text-overflow: ellipsis;
          border: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .attach-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
