
export const state = {
    userVariants: {
        shipments: {
            sorter: {
                name: "orderDepartureDateActual",
                active: false,
                key: "orderDepartureDateActual",
                asc: true
            }
        },
        requests: {
            sorter: {
                name: "requestLastMessage.actionDate",
                active: true,
                key: "requestLastMessage.actionDate",
                asc: true
            }
        },
        paymentsHistory: {
            sorter:  {
                name: "documentPostingDate",
                active: false,
                key: "documentPostingDate",
                asc: true
              }
        }
    },
}