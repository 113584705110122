/* eslint-disable no-unused-vars */
import axios from 'axios';
import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  login: localStorage.getItem('login') || '',
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refreshToken') || ''
}

export const getters = {
  isLoggedIn: function (state) {
    const userToken = new URLSearchParams(window.location.search).get(
      "token"
    );
    const userRefreshToken = new URLSearchParams(window.location.search).get(
      "refreshToken"
    );
    if (userToken && userRefreshToken) {
      localStorage.setItem('token', userToken);
      localStorage.setItem('refreshToken', userRefreshToken);
      localStorage.setItem('isAdminPreview', true);
      state.token = userToken;
      state.refreshToken = userRefreshToken;
    } 
      return !!state.login || !!state.token && !!state.refreshToken    
  }
}

export const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {

      axios.post(`${process.env.VUE_APP_API_AUTH}account/login`, JSON.stringify({ login: user.login, password: user.password }),
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          let encodedToken = 'Bearer ' + response.data.accessToken;
          let refreshToken = response.data.refreshToken;
          localStorage.setItem('token', encodedToken);
          localStorage.setItem('refreshToken', refreshToken);
          ApiService.defaults.headers.authorization = encodedToken;

          localStorage.setItem('login', user.login);
          commit('SET_LOGIN', user.login);

          resolve();
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('login');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.setItem('isAdminPreview', false);
      state.token = '';
      state.refreshToken = '';
      ApiService.defaults.headers.authorization = "";
      delete axios.defaults.headers.common['Authorization'];
      commit('LOGOUT');
      resolve();
    })
  }
}

export const mutations = {
  SET_LOGIN(state, login) {
    state.login = login;
    this._modules.root.state.user = {};
    this._modules.root.state.mainPageData = {};
  },
  LOGOUT(state) {
    this._modules.root.state.user = {};
    this._modules.root.state.mainPageData = {};
    state.login = '';
  }
}
