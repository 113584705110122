<template>
  <a class="list-item" @click="clickItem">
    <div class="state" :class="getStatusClass(request?.requestStatus)" />
    <div class="item-info">
      <div class="item-info_main fourColumn desktop-center-justify">
        <p class="bold">{{ request?.requestTitle }}</p>
        <p>{{ request?.requestOrder.orderInternalId }}</p>
      </div>
      <div class="item-info_main fourColumn">
        <div class="form-item">
          <label>{{ $t("request.label.orderId") }}</label>
          <div>{{ formatTextField(request?.requestOrder.orderNumber) }}</div>
        </div>
        <p>{{ request?.requestOrder.orderFrom }} - {{ request?.requestOrder.orderTo }}</p>
        <p>{{ request?.requestOrder.orderCargoName }}</p>
      </div>
      <div class="fourColumn">
        <div class="form-item">
          <label>{{ $t("request.label.createdDate") }}</label>
          <div>{{ formatDateField(request?.requestCreation?.actionDate) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("request.label.lastMessageDate") }}</label>
          <div>{{ formatTimeFromNowField(request?.requestLastMessage?.actionDate) }}</div>
        </div>
      </div>
      <div class="fourColumn">
        <div class="form-item">
          <label>{{ $t("request.label.createdAuthor") }}</label>
          <div>{{ formatTextField(request?.requestCreation?.actionAuthor) }}</div>
        </div>
        <div class="form-item">
          <label>{{ $t("request.label.lastMessageAuthor") }}</label>
          <div>{{ formatTextField(request?.requestLastMessage?.actionAuthor) }}</div>
        </div>
      </div>
    </div>
    <div class="unread-message"  :style="{ visibility: request?.hasUnread ? 'visible' : 'hidden' }">
      <font-awesome-icon icon="fa-solid fa-circle" />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    request: {
      type: Object,
      default: Object.create({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    clickItem() {
      this.$emit('onRequestClick', this.request?.requestId)
    }
  }
    
};
</script>

<style lang="scss">
.item-info_main.desktop-center-justify {
  justify-content: center;
}
</style>
