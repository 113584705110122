/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import ShipmentsView from '@/views/ShipmentsView.vue';
import RequestsView from '@/views/RequestsView.vue';
import BillingsView from '@/views/BillingsView.vue';
import ReportsView from '@/views/ReportsView.vue';
import SettingsView from '@/views/SettingsView.vue';

Vue.use(VueRouter)

const routes = [ 
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/shipment',
    name: 'shipment',
    component: ShipmentsView
  },
  {
    path: '/shipment/:shipmentId',
    name: 'shipmentDetail',
    component: ShipmentsView
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsView
  },
  {
    path: '/request/:requestId',
    name: 'requestDetail',
    component: RequestsView
  },
  {
    path: '/billings',
    name: 'billings',
    component: BillingsView
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  window.history.previousUrl = from.fullPath;
  if (to.name === "login") {
    next();
  } else if (router.app.$store.getters['secure/isLoggedIn']) {
    next();
  } else {
    next('/login');    
  }
});

export default router
