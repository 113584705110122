<template>
  <div>    
    <mobile-nav/>
    <desktop-nav/>
  </div>
</template>

<script>
import DesktopNav from "@/components/commonLib/nav/DesktopNav.vue";
import MobileNav from "@/components/commonLib/nav/MobileNav.vue";

export default {
  props: {},
  computed: {
    currentRoute() {
      return this.$t(this.$route.name);
    },
  },
  methods: {},
  data() {
    return {      
    };
  },
  components: {
    DesktopNav,
    MobileNav,
  },
};
</script>
