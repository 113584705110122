import ApiService from "@/services/ApiService";

export default {
  getUser() {
    return ApiService.get("/client/personalData");
  },
  setClientUpdate(user) {
    return ApiService.put("/client/personalData/update", user);
  },
  getDeltaSchedule() {
    return ApiService.get("/client/DeltaSchedule");
  },
  getMainPageData() {
    return ApiService.get("/client/mainPageData");
  },
  getShipments(sClientIds) {
    return ApiService.get("/client/orders/list?clientIds=" + sClientIds);
  },
  getMutualPaymentsBalance() {
    return ApiService.get("/client/mutualPayments/balance");
  },
  getMutualPaymentsDocuments() {
    return ApiService.get("/client/mutualPayments/documents/list");
  },
  setRate(orderId, oBody) {
    return ApiService.put("/client/orders/item/" + orderId + "/rate", oBody);
  },
  getShipment(sId) {
    return ApiService.get("/client/orders/item/" + sId);
  },
  getUnpaidBills() {
    return ApiService.get("/unpaidBills");
  },
  getShipmentDocuments(sId) {
    return ApiService.get("/shipmentDocuments/" + sId);
  },
  getRequests() {
    return ApiService.get("/client/requests/list");
  },
  getRequest(sId) {
    return ApiService.get("/client/requests/item/" + sId);
  },
  sendMessage(sId, oMessage) {
    return ApiService.post("/client/requests/item/" + sId + "/messages/send", oMessage);
  },
  markMessageSeen(sId, aMessagesId) {
    return ApiService.put("/client/requests/item/" + sId + "/messages/markRead", aMessagesId);
  },
  createRequest(sId, oBody) {
    return ApiService.post("/client/orders/item/" + sId + "/requests/create", oBody);
  },
  closeRequest(sId) {
    return ApiService.put("/client/requests/item/" + sId + "/changeStatus?close=true");
  },
  getBalanceData() {
    return ApiService.get("/balanceReportData");
  },
  getClientBalanceReportData() {
    return ApiService.get("/clientBalanceReportData");
  },
  getPaymentPeriodData() {
    return ApiService.get("/paymentPeriodData");
  },
  getReports(oStartDate, oEndDate, sClientIds) {
    return ApiService.get("/client/reports?start=" + oStartDate + "&end=" + oEndDate + "&clientIds=" + sClientIds);
  },
  getSummaryReport(sClientIds) {
    return ApiService.get("/client/orders/report?clientIds=" + sClientIds);
  },
  getShipmentsForChart() {
    return ApiService.get("/shipments");
  },
  getDocumentTags() {
    return ApiService.get("/client/tags/list");
  },
  sendAttachment(requestId, file) {
    return ApiService.post("/client/requests/item/" + requestId + "/messages/sendAttachment", file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getShipmentTracking(sOrderid) {
    return ApiService.get("/client/orders/item/" + sOrderid + "/tracking");
  },
  getArchiveTracking(sOrderid) {
    return ApiService.get("/client/orders/item/" + sOrderid + "/trackingArchive");
  },
  getClientGroup() {
    return ApiService.get("/client/clientGroup");
  },
  getRailwayTracking(sOrderid) {
    return ApiService.get("/client/orders/item/" + sOrderid + "/trackingRailway");
  }
}
