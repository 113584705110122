<template>
  <div class="bar-chart">
    <div class="horizontal-layout margin-top-large"></div>
    <div style="display: inline-block; width: 100%; height: -webkit-fill-available; height: -moz-available">
      <Bar style="display: none" :options="chartOptions" :data="chartData" chart-id="paymentPeriodSumID" :height="300" />
      <h3 style="color: #2c3e50; text-align: center">{{ $t('txt.inDevelopment') }}</h3>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from "chart.js";
import MockService from "@/services/MockService";

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale);
ChartJS.defaults.font.family = "Montserrat";
export default {
  components: {
    Bar
  },
  props: {
    dateRange: Object,
    reportData: {
      type: new Array(),
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false
          },
          y: {
            stacked: false,
            title: {
              display: true,
              text: "USD"
            },
            ticks: {
              // Include a dollar sign in the ticks
              // eslint-disable-next-line no-unused-vars
              callback: function (value, index, ticks) {
                return "$" + value;
              }
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: (e) => {
              return;
            },
            labels: {
              boxWidth: 20
            }
          }
        }
      }
    };
  },
  watch: {
    reportData() {
      let oUnpaidDataSet = {
        label: this.$t("report.payment.unpaid"),
        backgroundColor: "#F26C41",
        maxBarThickness: 25,
        data: []
      };
      let oPaidDataSet = {
        label: this.$t("report.payment.paid"),
        backgroundColor: "#2c3e50",
        maxBarThickness: 25,
        data: []
      };
      let aLabel = [];

      (this.reportData[0]?.chartData || []).forEach((oElement) => {
        oUnpaidDataSet.data.push(oElement.unPaid);
        oPaidDataSet.data.push(oElement.paid);
        aLabel.push(this.$moment(oElement.date).format("MMM YY"));
      });

      let oChartData = JSON.parse(JSON.stringify(this.chartData));
      oChartData.labels = aLabel;
      oChartData.datasets = [oPaidDataSet, oUnpaidDataSet];

      this.chartData = oChartData;
    }
  }
};
</script>

<style>
</style>
