<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar position="static" :expand-on-hover="false" :expand-on-hover-fixed="false" :reduce="reduce" type="is-dark" open class="customMenu">
        <div class="p-1">
          <div class="menu-btn">
            <button v-on:click="onReduceMenu">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </button>
          </div>
          <router-link to="/" class="block">
            <img src="@/assets/transasia.png" alt="tranasia" />
            <img src="@/assets/shortLogo.png" alt="tranasia" />
          </router-link>
          <b-menu>
            <route-links />
          </b-menu>
          <a class="footer-button" @click="openDevDialog" style="display: none">
            <div class="footer-icon">
              <font-awesome-icon icon="fa-solid fa-circle-question" class="fasIcon" />
            </div>
            <h2>{{ $t("title.writeToDev") }}</h2>
          </a>
          <div class="footer-version">
            <h4>
              {{ this.$store.state.frontendVersion}} <br />
              {{ this.$store.state.backendVersion }}
            </h4>
          </div>
        </div>
      </b-sidebar>
    </section>
    <the-popup ref="popup" :closable="true" height="12rem" width="20rem">
      <write-to-dev-dialog></write-to-dev-dialog>
    </the-popup>
  </div>
</template>

<script>
import RouteLinks from "@/components/RouteLinks.vue";
import WriteToDevDialog from "@/components/commonLib/WriteToDevDialog.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import { mapState } from "vuex";
export default {
  name: "DesktopNav",
  data() {
    return {
      expandOnHover: false,
      expandWithDelay: true,
      reduce: true
    };
  },
  mounted() {
    document.getElementById("pageContentBlock").onclick = () => {
      this.reduce = true;
    };
  },
  methods: {
    openDevDialog() {
      this.$refs.popup.open();
    },
    onReduceMenu() {
      this.reduce = this.reduce ? false : true;
    }
  },
  components: {
    RouteLinks,
    WriteToDevDialog,
    ThePopup
  },
  computed: {
    ...mapState(["backendVersion"])
  }
};
</script>

<style lang="scss">
$primaryColor: #2c3e50;
$lightColor: #c1c1c1;

.sidebar-page {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .sidebar-page {
    display: none !important;
  }
}

@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .sidebar-page {
    display: none !important;
  }
}

.customMenu .p-1 {
  height: 100vh;
}

.b-sidebar.customMenu .sidebar-content.is-mini {
  width: 70px;
}

.customMenu .menu-list a.is-active {
  background-color: $lightColor !important;
}

.customMenu .sidebar-content.is-dark {
  background-color: $primaryColor !important;
}

.customMenu .menu-list a {
  color: $lightColor;
  margin-bottom: 0.5rem;
}

.customMenu {
  h2 {
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    margin-left: 0.5rem;
    transition: visibility 0s, opacity 0.5s linear;
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }

  .block {
    margin-top: 1rem;
    height: 1.8rem;
    display: flex;
    padding-left: 0.35rem;
  }

  .block img:nth-child(1) {
    display: none;
  }

  .block img:nth-child(2) {
    display: inline;
    width: 50px;
  }
}

.is-mini .block img:nth-child(2) {
  display: inline;
}

.customMenu .sidebar-content:not(.is-mini) {
  text-align: left;

  h2 {
    visibility: visible;
    display: block !important;
    opacity: 1;
  }

  .block img:nth-child(1) {
    display: inline;
    width: 240px;
  }

  .block img:nth-child(2) {
    display: none;
  }
}

.customMenu .menu-list a {
  color: #ffffff;
  opacity: 70%;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.customMenu .menu-list a div {
  width: 30px;
  text-align: center;
}

.customMenu a svg {
  font-size: 1.2rem;
  width: 30px;
}

.customMenu a.router-link-exact-active {
  color: #ffffff;
  opacity: 100%;
}

.customMenu .footer-button {
  position: fixed;
  bottom: 0;
  display: flex;
  text-align: center;
  flex-direction: row;
  color: #ffffff;
  margin-bottom: 0.5rem;
  opacity: 70%;
  padding: 0.5em 0.75em;
  border-radius: 2px;

  h2 {
    display: none;
  }

  &:hover {
    background-color: whitesmoke;
    color: #363636;
  }

  .footer-icon {
    svg {
      font-size: 1.2rem;
      width: 30px;
    }
  }
}
.footer-version {
  position: fixed;
  bottom: 0;
  display: flex;
  text-align: center;
  flex-direction: row;
  color: #ffffff;
  margin-bottom: 0.5rem;
  opacity: 70%;
  padding: 0.5em 0.75em;
  border-radius: 2px;
}

.menu-btn {
  bottom: 0;
  display: flex;
  text-align: center;
  flex-direction: row;
  color: #ffffff;
  margin-bottom: 0.5rem;
  opacity: 70%;
  padding: 0.5em 0.75em;
  border-radius: 2px;
}
</style>
