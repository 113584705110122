<template>
  <section class="custom-panel">
    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open="isOpened" @update:open="onCollapseToggle">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open">
            <p class="card-header-title">{{ title }}</p>
            <a class="card-header-icon">
              <b-icon size="is-small" :icon="props.open ? 'fa-chevron-down' : 'fa-chevron-up'" class="customIcon"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <slot></slot>
        </div>        
      </b-collapse>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    isOpened: {
      type:Boolean,
      default: false
    }
  },
  methods: {
    onCollapseToggle(newOpenState) {
      this.$emit('collapseToggle', newOpenState);
    }
  }
}
</script>

<style lang="scss">
.custom-panel {
    .card-header-title {
        font-size: 0.8rem;
    }

    .card-content {
        padding: 0.5rem;
    }    
}
</style>
