<template>
  <div class="custom-rate" @click="disabledPropagationClick">
    <b-rate :size="size" icon="fa-star" @change="e => $emit('rateChanged', e)" v-model="rate" :disabled="disabled"/>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number
    },
    size: {
      type: String,
      default: 'is-medium'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    rating(newValue) {
      this.rate = this.rating;
    }
  },
  data() {
    return {
      rate: this.rating
    };
  },
  methods: {
    disabledPropagationClick(e) {
      e.stopPropagation();
    }
  },
};
</script>

<style lang="scss" >
.custom-rate .icon.is-medium {
    width: 2rem;
    svg {
      height: 2rem;
    }
}
.custom-rate .icon.is-small {
  width: 1.3rem;
    svg {
      height: 1.2rem;
    }
}
</style>
