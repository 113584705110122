import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store/index';
import router from '@/router';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import '@/assets/main.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHouse, faClipboardList, faComments, faGear, faUser, faRightFromBracket, faBars, faFileInvoiceDollar, faChartColumn, faSearch, faDownload, 
  faFilter, faArrowDownWideShort, faClockRotateLeft, faArrowLeft, faArrowRight, faChevronDown, faChevronUp, faStar,
  faCheck, faEllipsis, faTruck, faCommentDollar, faChevronLeft, faChevronRight, faEye, faEyeSlash, faFileLines, faPaperclip, faPaperPlane, faCircle, 
  faCalendarDay, faClose, faAngleRight, faAngleLeft, faPlus, faMinus, faRotate, faCircleQuestion, faCaretDown, faUpload, faSort, faTrain, 
  faTruckFront} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from '@/i18n';
import formatter from '@/mixins/formatter';
import authActions from '@/mixins/authActions';
import "vue2-datepicker/index.css";
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import '@/assets/datepicker.scss';
import ApiService from "@/services/ApiService";
import Moment from 'moment';
import './registerServiceWorker';
import * as VueGoogleMaps from 'vue2-google-maps';

Moment.locale('ru');
Vue.prototype.$moment = Moment;

library.add(faHouse, faClipboardList, faComments, faGear, faUser, faRightFromBracket, faBars, faFileInvoiceDollar, faChartColumn, faSearch, faDownload,
   faFilter, faArrowDownWideShort, faClockRotateLeft, faArrowLeft, faArrowRight, faChevronDown, faChevronUp, faStar, faCheck,
   faEllipsis, faTruck, faCommentDollar, faChevronLeft, faChevronRight, faEye, faEyeSlash, faFileLines, faPaperclip, faPaperPlane, faCircle,
   faCalendarDay, faClose,faAngleLeft, faAngleRight, faPlus, faMinus, faRotate, faCircleQuestion, faCaretDown, faUpload, faSort, faTrain, faTruckFront);
Vue.component('font-awesome-icon', FontAwesomeIcon);

const token = localStorage.getItem('token');
if (token) {
  ApiService.defaults.headers.common['Authorization'] = token;
}
Vue.config.productionTip = false;
Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
});
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyD9C7QYS-L-8UQlcMAPv9bUrDfKV7WLzr0', старый ключ
    key: 'AIzaSyAGElJQA_nZLQ1MYny6oePry50zSPkmVO0',
    libraries: 'places'
  }
});
Vue.mixin(formatter);
Vue.mixin(authActions);

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
