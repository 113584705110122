<template>
  <div>
    <div class="colorfullList">
      <div class="list-items">
        <request-item v-show="conversations.length > 0" v-for="request in conversations" :key="request.requestId" :request="request" @onRequestClick="onRequestClick" />
        <div v-show="conversations.length === 0" class="no-items">
          {{ $t("request.text.noData") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import RequestItem from "@/components/requests/RequestItem.vue";
export default {
  components: {
    RequestItem
  },
  props: {
    conversations: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onRequestClick(sId) {
      this.$router.push({
        name: "requestDetail",
        params: { requestId: sId }
      });
    }
  }
};
</script>

<style>
</style>
