<template>
  <div class="requests-view">
    <div v-if="isDetail">
      <request-detail />
    </div>
    <div class="colorfullList" v-else>
      <toolbar-panel @onSearch="onSearchTable" @onFilter="onFilterTable" :isFilterApplied="isFilterApplied">
        <template v-slot:left>
          <the-select :sortList="defaultSorters" sortView="requests" @change="onGetSorterParams" />
        </template>
      </toolbar-panel>
      <the-popup ref="popup" :closable="true" height="30rem">
        <the-filter-dialog @filter="onGetFilteringParams" :defaultSettings="defaultFilterSettings" />
      </the-popup>
      <div class="customPagination">
        <div class="pageRecordsCount">
          <h4>{{ $t("shipment.text.numberOfRecords") + ':' }}</h4>
          <b-field>
            <b-radio-button v-for="pageCount in recordCountPerRage" :key="pageCount.key" v-model="pagination"
              :native-value="pageCount.key" size="is-small" type=" is-primary">
              <span>{{ pageCount.key }}</span>
            </b-radio-button>
          </b-field>
        </div>
        <b-pagination class="customInput" v-if="isPaginationNeeded" :total="filteredList.length" v-model="currentPage" order="is-left" size="is-small" :per-page="pagination" />
      </div>
      <div class="list-items">
        <request-item v-show="requests.length > 0" v-for="request in paginatedList" :key="request.requestId" :request="request" @onRequestClick="onRequestClick" />
        <div v-show="requests.length === 0 && !isLoading" class="no-items">
          {{ $t("request.text.noData") }}
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import ToolbarPanel from "@/components/commonLib/ToolbarPanel.vue";
import RequestItem from "@/components/requests/RequestItem.vue";
import RequestDetail from "@/components/requests/RequestDetail.vue";
import TheFilterDialog from "@/components/commonLib/TheFilterDialog.vue";
import ThePopup from "@/components/commonLib/ThePopup.vue";
import TheSelect from "@/components/commonLib/TheSelect.vue";

export default {
  components: {
    ToolbarPanel,
    RequestItem,
    RequestDetail,
    TheFilterDialog,
    ThePopup,
    TheSelect
  },
  data() {
    return {
      isDetail: false,
      requests: [],
      pagination: 30,
      recordCountPerRage: [{ key: 15 }, { key: 30 }, { key: 50 }],
      isLoading: false,
      detailedRequest: {},
      searchingFields: [
        "requestTitle",
        "requestOrder.orderCargoName",
        "requestOrder.orderNumber",
        "requestOrder.orderInternalId",
        "requestOrder.orderFrom",
        "requestOrder.orderTo",
        "requestOrder.orderCargoName",
        "requestCreation.actionAuthor",
        "requestLastMessage.actionAuthor"
      ],
      presetFilters: null,
      presetSorter: this.$store.state.variants?.userVariants?.requests?.sorter ? this.$store.state.variants.userVariants.requests.sorter : null,
      currentPage: 1,
      isFilterApplied: false,
      searchValue: "",
      defaultFilterSettings: {
        filters: [
          {
            name: "requestCreation.actionDate",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "requestLastMessage.actionDate",
            type: "rangedatepicker",
            value1: "",
            value2: ""
          },
          {
            name: "requestOrder.orderFrom",
            type: "input",
            value1: null
          },
          {
            name: "requestOrder.orderTo",
            type: "input",
            value1: null
          }
        ],
        sorters: []
      },
      defaultSorters: [
        {
          name: "requestStatus",
          active: false,
          key: "requestStatus",
          asc: true
        },
        {
          name: "requestLastMessage.actionDate",
          active: false,
          key: "requestLastMessage.actionDate",
          asc: true
        },
        {
          name: "hasUnread",
          active: false,
          key: "hasUnread",
          asc: false
        }
      ]
    };
  },
  computed: {
    filteredList() {
      return this.requests;
    },
    isPaginationNeeded() {
      return this.pagination !== 0 && this.filteredList.length > this.pagination;
    },
    paginatedList() {
      if (this.isPaginationNeeded) {
        return this.paginate(this.filteredList, this.pagination, this.currentPage);
      } else {
        return this.filteredList;
      }
    }
  },
  watch: {
    $route(to) {
      if (to.name === "requestDetail") {
        this.isDetail = true;
        this.presetFilters = null;
        this.searchValue = "";
      } else {
        this.isDetail = false;
        this.isLoading = true;
        MockService.getRequests()
          .then((oResponse) => {
            this.requests = oResponse.data.contactRequests;
          })
          .catch((error) => {
            this.errorMessage(error.response?.data.errorMessage);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  },
  methods: {
    onFilterTable() {
      this.$refs.popup.open();
    },

    onSearchTable(oEvent) {
      this.searchValue = oEvent.value;
      this.getRequestsList();
    },

    onGetFilteringParams(oFilters) {
      this.$refs.popup.close();
      this.presetFilters = oFilters.filters.length > 0 ? oFilters : null;
      this.getRequestsList();
      this.isFilterApplied = !!this.presetFilters;
    },
    
    onGetSorterParams(oSorter) {
      this.presetSorter = oSorter;
      this.getRequestsList();
    },

    getRequestsList() {
      this.isLoading = true;
      MockService.getRequests()
        .then((oResponse) => {
          let aRequests = oResponse.data.contactRequests;
          if (this.presetFilters) {
            aRequests = this.filterAndSortArray(aRequests, this.presetFilters);
          }
          if (this.searchValue) {
            aRequests = this.searchDataByFields(aRequests, this.searchingFields, this.searchValue);
          }
          if (this.presetSorter) {
            aRequests = this.sortArray(aRequests, this.presetSorter);
          }
          this.requests = aRequests;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onRequestClick(sId) {
      this.$router.push({
        name: "requestDetail",
        params: { requestId: sId }
      });
    },
    fetchRequest() {
      const sId = this.$route.params.requestId;
      MockService.getRequest(sId)
        .then((oResponse) => {
          this.detailedRequest = oResponse.data;
        })
        .catch((error) => {
          this.errorMessage(error.response?.data.errorMessage);
        });
    }
  },
  created() {
    if (this.$route.name === "requestDetail") {
      this.isDetail = true;
    } else {
      this.getRequestsList();
    }
  }
};
</script>

<style lang="scss">
.requests-view {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .filterbar {
    width: 100%;
    justify-content: flex-end;
  }
}

.requests-view > div {
  height: 100%;
  overflow: auto;
  height: -webkit-fill-available;
  height: -moz-available;
  display: flex;
  flex-direction: column;
}
</style>
