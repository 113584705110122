<template>
  <div class="settings-page">
    <div class="client-settings">
      <b-button size="is-small" type="is-primary" v-show="!isEditing" @click="onEdit">{{ $t("btn.edit") }}</b-button>
      <b-button size="is-small" type="is-primary" v-show="isEditing" @click="onSaveSettings">{{ $t("btn.save") }}</b-button>
      <b-button size="is-small" type="is-primary" v-show="isEditing" @click="onCancel">{{ $t("btn.cancel") }}</b-button>
    </div>
    <div class="client-info-block">
      <div class="client-icon">
        <font-awesome-icon icon="fa-solid fa-user" />
      </div>
      <div class="client-main-form">
        <div>
          <h2 class="section-title">{{ $t("settings.label.mainInfo") }}</h2>
          <div class="client-form">
            <div class="form-item">
              <label>{{ $t("settings.label.login") }}</label>
              <div>{{ formatTextField($store.state.user.userLogin) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.fio") }}</label>
              <div>{{ formatTextField($store.state.user.userFullname) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.email") }}</label>
              <div>
                <span v-if="!isEditing">{{ formatTextField($store.state.user.userEmail) }}</span>
                <div v-else>
                  <b-input :class="{ invalid: !isEmailValid }" v-model="userChanges.userEmail" type="text" size="is-small" @blur="emailChange('blur')" @input="emailChange('input')" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.company") }}</label>
              <div>{{ formatTextField($store.state.user.userOffice) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.phone") }}</label>
              <div>
                <span v-if="!isEditing">{{ formatTextField($store.state.user.userPhoneNumber) }}</span>
                <div v-else>
                  <b-input v-model="userChanges.userPhoneNumber" type="text" size="is-small" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.address") }}</label>
              <div>{{ formatTextField($store.state.user.userAddress) }}</div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.telegram") }}</label>
              <div>
                <span v-if="!isEditing">{{ formatTextField($store.state.user.userTelegram) }}</span>
                <div v-else>
                  <b-input v-model="userChanges.userTelegram" placeholder="nickname" type="text" size="is-small" />
                </div>
              </div>
            </div>
            <div class="form-item margin-top">
              <label>{{ $t("settings.label.language") }}</label>
              <div>
                <span v-if="!isEditing">{{ languages.find((oLang) => oLang.key === $store.state.user.userLanguage)?.text }}</span>
                <div v-else>
                  <b-field class="hideOnMobile">
                    <b-radio-button v-for="language in languages" :key="language.key" v-model="userChanges.userLanguage" :native-value="language.key" size="is-small" type=" is-primary">
                      <span>{{ language.text }}</span>
                    </b-radio-button>
                  </b-field>
                  <div class="hideOnDesktop">
                    <b-dropdown size="is-small" v-model="userChanges.userLanguage" :mobile-modal="false" position="is-bottom-right" class="customDropDown" scrollable max-height="220">
                      <template #trigger> <b-button size="is-small" icon-right="chevron-down" :label="userLanguageText" class="customButton" /></template>
                      <b-dropdown-item size="is-small" v-for="language in languages" :key="language.key" :value="language.key">{{ language.text }}</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 class="section-title">{{ $t("settings.label.notificationSettings") }}</h2>
          <div class="client-form">
            <div class="form-item">
              <label>{{ $t("settings.label.duplicateToEmail") }}</label>
              <div>
                <b-checkbox v-if="isEditing" v-model="userChanges.repeatOnMailbox" size="is-small" />
                <div v-else>{{ !!$store.state.user.repeatOnMailbox ? $t("txt.yes") : $t("txt.no") }}</div>
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.notifyNewMessages") }}</label>
              <div>
                <b-checkbox v-if="isEditing" v-model="userChanges.notifyNewMessages" size="is-small" />
                <div v-else>{{ !!$store.state.user.notifyNewMessages ? $t("txt.yes") : $t("txt.no") }}</div>
              </div>
            </div>
            <div class="form-item">
              <label>{{ $t("settings.label.clients") }}</label>
              <div>
                <multiselection-control v-if="isEditing" :items="clientList" :selectedItems="$store.state.user.mailboxClientList" textBinding="clientName" keyBinding="clientId" @change="changeReportClients" width="15rem"></multiselection-control>
                <div v-else class="client-report-names">
                  <h4 v-for="client in $store.state.user.mailboxClientList" :key="client.clientId" >{{ client.clientName }}</h4>
                </div> 
              </div>
            </div>
            <div class="form-item hours-item">
              <label>{{ $t("settings.label.notificationHours") }}</label>
              <div class="notification-hours-from">
                <multiselection-control v-if="isEditing" :items="timerange" :selectedItems="selectedUserNotificationHours" @change="changeSelectedSchedules" keyBinding="hour" textBinding="hour" width="15rem" />
                <div v-else class="notification-hours">
                  <span v-for="hour in selectedUserNotificationHours" :key="hour.hour">{{ hour.hour }}</span>
                </div>
                <h4 class="timezone-label" style="margin-right: 0.5rem">{{ this.$moment.tz(this.$moment.tz.guess()).format("(UTCZ)") }} {{ this.$moment.tz.guess() }}</h4>
              </div>
            </div>
            <div class="delta-schedule">
              <h4>{{ $t("settings.label.DeltaSchedule") }}</h4>
              <h4 v-for="(hour, index) of intervalList" :key="index">{{ formatterUTC(hour) }}</h4>
              <h4>{{ this.$moment.tz(this.$moment.tz.guess()).format("(UTCZ)") }} {{ this.$moment.tz.guess() }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
import MultiselectionControl from "@/components/commonLib/MultiselectionControl.vue";
import moment_timezone from "moment-timezone";
import { mapState } from "vuex";
export default {
  data() {
    return {
      isEditing: false,
      defaultData: {},
      userChanges: {
        userEmail: this.$store.state.user.userEmail,
        userPhoneNumber: this.$store.state.user.userPhoneNumber,
        userTelegram: this.$store.state.user.userTelegram,
        userLanguage: this.$store.state.user.userLanguage,
        repeatOnMailbox: this.$store.state.user.repeatOnMailbox,
        notifyNewMessages: this.$store.state.user.notifyNewMessages,
        userNotificationHours: this.$store.state.user.userNotificationHours,
        mailboxClientList: this.$store.state.user.mailboxClientList
      },
      isEmailValid: true,
      languages: [
        { key: "RU", text: "Русский" },
        { key: "EN", text: "English" },
        { key: "UZ", text: "O'zbek" }
      ],
      timerange: [
        { hour: "00:00" },
        { hour: "01:00" },
        { hour: "02:00" },
        { hour: "03:00" },
        { hour: "04:00" },
        { hour: "05:00" },
        { hour: "06:00" },
        { hour: "07:00" },
        { hour: "08:00" },
        { hour: "09:00" },
        { hour: "10:00" },
        { hour: "11:00" },
        { hour: "12:00" },
        { hour: "13:00" },
        { hour: "14:00" },
        { hour: "15:00" },
        { hour: "16:00" },
        { hour: "17:00" },
        { hour: "18:00" },
        { hour: "19:00" },
        { hour: "20:00" },
        { hour: "21:00" },
        { hour: "22:00" },
        { hour: "23:00" }
      ],
      intervalList: [],
      notificationHours: this.$store.state.user.userNotificationHours,
      clientList: [],
      userMailboxClientList: [],
      isLoading: false
    };
  },
  components: {
    MultiselectionControl
  },
  created() {
    this.$moment.locale(this.$store.state.user.userLanguage);
    MockService.getDeltaSchedule()
      .then((oResponse) => {
        this.intervalList = oResponse.data.intervalList;
      })
      .catch(() => {});
    MockService.getClientGroup()
      .then((oResponse) => {
        this.clientList = oResponse.data.clientGroup;
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage);
      });
  },
  beforeUpdate() {
    if (this.$store.state.user.mailboxClientList) {
      for (let i = 0; i < this.$store.state.user.mailboxClientList?.length; i++) {
        const sClientId = this.$store.state.user.mailboxClientList[i];
        const foundClient = this.clientList.find((element) => element.clientId === sClientId);

        if (foundClient) {
          this.$store.state.user.mailboxClientList[i] = {clientId: sClientId, clientName: foundClient.clientName};
        }
      }
    } else {
      this.$store.state.user.mailboxClientList = [];
    }
  },
  computed: {
    userLanguageText() {
      return this.languages.find((language) => language.key.toLowerCase() === this.userChanges.userLanguage.toLowerCase()).text;
    },
    selectedUserNotificationHours() {
      let userNotificationHours = [];
      let aNotificationHours = Object.values(this.$store.state.user.userNotificationHours);
      aNotificationHours.map((iHour) => {
        const UTCHour = this.$moment().hour(iHour);
        const localHour = this.$moment(UTCHour).utc(UTCHour).local().hours();
        const sHour = this.$moment(localHour, "HH").format("HH:mm");
        userNotificationHours.push({ hour: sHour });
      });
      return userNotificationHours;
    },
    ...mapState(["user"])
  },
  methods: {
    onEdit() {
      this.isEditing = true;
      this.userChanges.userEmail = this.$store.state.user.userEmail;
      this.userChanges.userPhoneNumber = this.$store.state.user.userPhoneNumber;
      this.userChanges.userLanguage = this.$store.state.user.userLanguage;
      this.userChanges.userTelegram = this.$store.state.user.userTelegram;
      this.userChanges.repeatOnMailbox = this.$store.state.user.repeatOnMailbox;
      this.userChanges.notifyNewMessages = this.$store.state.user.notifyNewMessages;
      this.userChanges.userNotificationHours = this.$store.state.user.userNotificationHours;
      this.userChanges.mailboxClientList = this.$store.state.user.mailboxClientList;
    },
    onSaveSettings() {
      if (this.isEmailValid) {
        this.isEditing = false;
        this.isLoading = true;
        // this.userChanges.mailboxClientList = this.userMailboxClientList ?
        // this.userMailboxClientList.forEach((client) => this.userChanges.mailboxClientList.push(client.clientId));
        this.userChanges.mailboxClientList = this.userMailboxClientList.map((oClient) => oClient.clientId);

        MockService.setClientUpdate(this.userChanges)
          .then(() => {
            this.$store.state.user.userEmail = this.userChanges.userEmail;
            this.$store.state.user.userPhoneNumber = this.userChanges.userPhoneNumber;
            this.$store.state.user.userLanguage = this.userChanges.userLanguage;
            this.$store.state.user.userTelegram = this.userChanges.userTelegram;
            this.$store.state.user.repeatOnMailbox = this.userChanges.repeatOnMailbox;
            this.$store.state.user.notifyNewMessages = this.userChanges.notifyNewMessages;
            this.$store.state.user.userNotificationHours = this.userChanges.userNotificationHours;
            this.$store.state.user.mailboxClientList = this.userMailboxClientList;
            this.isLoading = false;
            this.changeLocale(this.userChanges.userLanguage.toLowerCase());
            localStorage.setItem("language", this.userChanges.userLanguage.toLowerCase());
            this.updateHeader();
          })
          .catch((error) => {
            this.errorMessage(error.response?.data.errorMessage);
          });
      } else {
        this.errorMessage(this.$t("settings.warning.email"));
      }
    },
    onCancel() {
      this.userChanges.userEmail = this.$store.state.user.userEmail;
      this.userChanges.userPhoneNumber = this.$store.state.user.userPhoneNumber;
      this.userChanges.userLanguage = this.$store.state.user.userLanguage;
      this.userChanges.userTelegram = this.$store.state.user.userTelegram;
      this.userChanges.repeatOnMailbox = this.$store.state.user.repeatOnMailbox;
      this.userChanges.notifyNewMessages = this.$store.state.user.notifyNewMessages;
      this.userChanges.userNotificationHours = this.$store.state.user.userNotificationHours;
      this.userChanges.mailboxClientList = this.$store.state.user.mailboxClientList;
      this.isEditing = false;
      this.isEmailValid = true;
    },
    emailChange(eventType) {
      if (this.userChanges.userEmail === "") {
        this.isEmailValid = false;
        return;
      }
      if (/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.userChanges.userEmail)) {
        this.isEmailValid = true;
      } else {
        if (eventType === "blur") {
          this.isEmailValid = false;
          this.errorMessage(this.$t("settings.warning.email"));
        } else {
          this.isEmailValid = true;
        }
      }
    },
    changeSelectedSchedules(aValue) {
      let aSelectedHours = [];
      aValue.map((oHour) => {
        const sHour = this.$moment(oHour.hour, "HH:mm").format("HH");
        const localHour = this.$moment().hour(sHour);
        const UTCHour = this.$moment(localHour).utc().hour();
        aSelectedHours.push(UTCHour);
      });
      this.userChanges.userNotificationHours = aSelectedHours;
    },
    formatterUTC(sTime) {
      return this.$moment(sTime).format("HH:mm");
    },
    changeReportClients(aValue) {
      this.userMailboxClientList = aValue;
    }
  }
};
</script>

<style lang="scss">
.settings-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 1rem 0.5rem;

  .client-settings {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    justify-content: flex-end;
  }

  .client-info-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 1rem;

    .client-main-form {
      display: flex;
      gap: 10rem;

      .form-item {
        flex-direction: row;
        align-items: center;

        & > label {
          width: 10rem;
          text-align: end;
          flex: 0 0 auto;
        }

        .hideOnDesktop {
          display: none;
        }
      }
    }

    .form-item > div {
      margin-left: 0.5rem;
    }

    .client-icon {
      width: 12rem;
      text-align: center;
      svg {
        color: #2c3e50;
        height: 7rem;
      }
    }
  }

  .notification-hours-from {
    display: flex;
    .notification-hours {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
    }
    .timezone-label {
      margin-left: 0.5rem;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .client-report-names {
    display: flex;
    gap: 0.5rem;
  }

  .section-title {
    font-weight: bold;
    margin-bottom: 1rem;
    display: flex;
    box-shadow: 0px 2px 0px 0px #c1c1c1;
  }

  .delta-schedule {
    color: #949494;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 0.2rem;
  }

  .invalid {
    outline-width: 2px;
    outline-style: solid;
    outline-color: #f26c41;
    border-radius: 2px;
  }

  @media screen and (max-width: 600px) and (orientation: portrait) {
    .client-info-block {
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;
      height: calc(100% - 3rem);

      .client-main-form {
        display: block !important;
        margin-left: auto;
      }

      .form-item > label {
        width: auto;
        text-align: left;
      }
    }

    .client-settings {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      color: white;
      padding: 0.5rem;
      border-top: 1px solid whitesmoke;
    }
    .hideOnDesktop {
      display: block !important;
    }
    /*.delta-schedule {
    justify-content: start;
    }
    .notification-hours-from {
    display: block !important;
    }
    .hours-item {
      flex-direction: column !important;
    }*/
  }

  @media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
    .client-info-block {
      overflow-y: auto;

      .client-main-form {
        gap: 3rem;
        margin-top: auto;

        .client-form {
          height: 100%;
          overflow: auto;
          display: flex;
          flex-direction: column;
        }
      }
    }
    .hideOnDesktop {
      display: block !important;
    }
  }
}
</style>
