import { render, staticRenderFns } from "./BalanceBox.vue?vue&type=template&id=4e5ed7c4&scoped=true"
import script from "./BalanceBox.vue?vue&type=script&lang=js"
export * from "./BalanceBox.vue?vue&type=script&lang=js"
import style0 from "./BalanceBox.vue?vue&type=style&index=0&id=4e5ed7c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5ed7c4",
  null
  
)

export default component.exports