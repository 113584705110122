<template>
  <div>
    <div class="client-balance-block">
      <div class="overflow-table" id="productTable">
        <div class="product" v-for="(product, productIndex) in balanceReport" :key="productIndex">
          <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" :open="isOpen" @open="$emit('open')">
            <template #trigger="props">
              <div class="card-header" role="button">
                <h3 class="card-header-title" v-if="product.groupType === 'north'">
                  {{ product.productName }}
                </h3>
                <h3 class="card-header-title" v-if="product.groupType === 'south'">
                  {{ $t("report.balance.product.groupType.south") }}
                </h3>
                <b-button type="is-text" size="is-small" :icon-right="props.open ? 'fa-chevron-up' : 'fa-chevron-down'" class="show-btn customButton" />
              </div>
            </template>

            <div class="card-content">
              <div class="content">
                <div class="table-content">
                  <table class="balance-table-container" id="summaryTable">
                    <thead class="table-thead" style="">
                      <tr style="display: table-column-group;">
                        <th v-if="product.groupType === 'north'">{{ product.productName }}</th>
                        <th v-if="product.groupType === 'south'">{{ $t("report.balance.product.groupType.south") }}</th>
                      </tr>
                      <tr class="subheader-block" v-for="(balanceRow, indexRow) in product.rows" :key="indexRow"
                        v-show="product.open">
                        <th :colspan="balanceCol.colspan" :rowspan="balanceCol.rowspan" class="subheader_block-th"
                          v-for="(balanceCol, indexCol) in balanceRow" :key="indexCol">
                          {{ balanceCol.tag }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="product-order" v-for="dataRow in product.data" :key="dataRow.orderId" 
                        v-show="product.open" @click="selectOrder(dataRow.orderId)">
                        <td :colspan="1" :rowspan="1" v-for="columnData in dataRow.orderData" :key="columnData.tag" :class="columnData.tag === 'Commentary' ||  columnData.tag === 'orderCommentary' ? 'comment-tr' : 'no-wrap-tr'">{{
                          formatterTableValue(columnData.value) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
export default {
  components: {},
  props: {
    dateRange: Object,
    reportData: {
      type: Object,
      default: () => {
        return {
          data: [],
          total: {}
        };
      }
    }
  },
  data() {
    return {
      balanceReport: [],
      isOpen: true
    };
  },
  watch: {
    reportData() {
      let aResult = [];
      let aProduct = this.reportData.products;
      aProduct.forEach((oProduct) => {
        let productHeaderRows = [];
        let resHeaderRows = [];
        this.traverseTree(oProduct, -1, productHeaderRows);
        let maxLevel = productHeaderRows.length;

        for (let i = 0; i < productHeaderRows.length; i++) {
          resHeaderRows[i] = [];
          for (let j = 0; j < productHeaderRows[i].length; j++) {
            resHeaderRows[i][j] = {};
            resHeaderRows[i][j] = this.getInfoByCell(productHeaderRows[i][j], maxLevel, i);
          }
        }

        aResult.push({
          rows: resHeaderRows,
          data: oProduct.data,
          open: true,
          productName: oProduct.productName,
          groupType: oProduct.groupType,
          // colspan: oProduct.data[0].orderData.length,
          colspan: 2,
          rowspan: 1
        });
      });
      this.balanceReport = aResult;
    }
  },
  methods: {
    shipmentTitle(sOrder, sInternalId) {
      if (sOrder) {
        let sTitle = sOrder;
        sTitle += sInternalId ? ` (${sInternalId})` : "";
        return sTitle;
      } else {
        return this.$t("report.balance.undefinedOrder");
      }
    },
    getInfoByCell(oCell, maxLevel, level) {
      let column = oCell.subcolumns ? oCell.subcolumns : oCell.columns;
      return {
        tag: oCell.name ? oCell.name : this.$t("report.balance." + oCell.tag),
        // isProduct: oCell.productName ? true : false,
        colspan: column ? column.length : 1,
        rowspan: column ? 1 : maxLevel - level
      };
    },
    traverseTree(node, level, result) {
      if (!result[level] && level !== -1) {
        result[level] = [];
      }
      let columns = node.subcolumns ? node.subcolumns : node.columns;
      if (level !== -1) {
        result[level].push(node);
      }

      if (columns) {
        for (let i = 0; i < columns.length; i++) {
          this.traverseTree(columns[i], level + 1, result);
        }
      }
    },
    toggle(productIndex) {
      this.balanceReport[productIndex].open = !this.balanceReport[productIndex].open;
    },
    selectOrder(orderId) {
      this.$router.push({
        name: "shipmentDetail",
        params: { shipmentId: orderId }
      });
    },
    openAccordion(isOpen) {
      this.isOpen = isOpen;
    }
  }
};
</script>

<style lang="scss">
.balance-table-container {
  /*overflow-x: auto;
  width: -webkit-fill-available;*/
}

.client-balance-block {
  .overflow-table {
    /*overflow-x: auto;*/
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    /*.product {
      width: min-content;
    }*/
  }

  table {
    font-size: 0.725rem;
    /*min-width: 25rem;*/

    .table-thead th {
      position: sticky;
    }

    .table-thead {
      background-color: #2c3e50;



      .header_block-th {
        vertical-align: middle;
        color: white;
      }

      .header_block-value_th {
        text-align: end;
        vertical-align: middle;
        color: white;
      }

      .mergeColumn {
        padding-left: 0.5rem;
      }

      .subheader-header-text {
        border-bottom: 1px solid #ffffff35;
        margin-left: 1rem;
        padding-bottom: 0.5rem;
      }
    }

    .total-row-th {
      background-color: #f26c41;
      color: white;
    }

    .end-align {
      text-align: end;
    }
  }

  table th,
  table td {
    /*padding: 0.5rem 0.2rem;
    padding-top: 0.5rem;*/
  }

  tbody tr {
    border-bottom: 1px solid #00000015;
  }

  .no-wrap-tr{
    white-space: nowrap;
  }

  .comment-tr {
    width: 8rem;
  }

  .subheader_block-th {
    background-color: #ffffff;
  }
}

.redTextColor {
  color: #eb5f32;
}

.greenTextColor {
  color: #17873d;
}

.product-btn {
  text-align: start;
}

.content {
  overflow: auto;
  display: grid;
}

th {
  position: sticky;
}

.product-order {
  cursor: pointer;
}

.table-content {
  overflow-x: auto;
}

.show-btn {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
