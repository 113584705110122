<!-- eslint-disable vue/no-unused-components -->
<template>
  <div class="billings">
    <div class="billings-title">
      <font-awesome-icon icon="fa-solid fa-file-invoice-dollar" />
      <h2 class="margin-left">{{ $t("billings.title.balance") }}</h2>
    </div>
    <balance-box class="block-margin-bottom"></balance-box>
    <h1 style="display: none" class="billings-title margin-bottom">
      <font-awesome-icon icon="fa-solid fa-clock-rotate-left" />
      <h2 class="margin-left">{{ $t("billings.title.history") }}</h2>
    </h1>
    <payment-history style="display: none" :bClosedPayments="false"></payment-history>
    <!-- <the-tabs style="width: 100%; margin-top: 0.5rem; padding: 0.1rem">
      <b-tab-item :label="$t('billings.history.tab.opened')">
        <payment-history :bClosedPayments="false"></payment-history>
      </b-tab-item>
      <b-tab-item :label="$t('billings.history.tab.closed')">
        <payment-history :bClosedPayments="true"></payment-history>
      </b-tab-item>
    </the-tabs> -->
  </div>
</template>

<script>

import BalanceBox from "@/components/billings/BalanceBox.vue";
// import TheTabs from "@/components/TheTabs.vue";
import PaymentHistory from "@/components/billings/PaymentHistory.vue";
export default {
  components: {
    BalanceBox,
    PaymentHistory,
    // TheTabs
  },
};
</script>

<style lang="scss" scoped>
.billings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: auto;
  padding: 0.1rem;
  padding-bottom: 1.5rem;
}
.billings-title {
  display: flex;
  margin-bottom: 0.5rem;
  svg {
    color: #2c3e50;
  }
}

.billing-box-shadow {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.block-margin-bottom {
  margin-bottom: 1.5rem;
}

.box {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
