<template>
  <div class="mobile-nav-header">
    <div class="horizontal-layout align-center">
      <b-button type="is-primary is-small is-rounded" icon-right="fa-arrow-left" class="customButton navBackMobile" inverted @click="navBack" />
      <h3 class="margin-left">{{ title }}</h3>
    </div>
    <slot></slot>
    <!-- <div class="horizontal-layout">
      <b-button type="is-primary is-small" icon-right="fa-comments" class="customButton" inverted @click="navBack" />
      <b-button type="is-primary is-small" icon-right="fa-download" class="margin-left customButton" @click="navBack" />
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  methods: {
     navBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">

.mobile-nav-header {
  display: none;
}

.customButton.navBackMobile {
    width: 0.5rem;
}

@media screen and (max-width: 600px) and (orientation: portrait){
  .mobile-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-height: 600px) and (orientation: landscape) and (max-width: 900px) {
  .mobile-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
