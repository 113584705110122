<template>
  <div class="history-timeline">
    <div v-show="!shipmentHistory?.length" class="no-items">
      {{ $t("history.text.noData") }}
    </div>
    <div class="columns is-mobile is-two-thirds-tablet desktop-visibility" v-show="shipmentHistory?.length">
      <div class="column"></div>
      <div class="column">
        <label>{{ $t("shipment.history.label.planDate") }}</label>
      </div>
      <div class="column">
        <label>{{ $t("shipment.history.label.factDate") }}</label>
      </div>
      <div class="column">
        <label>{{ $t("shipment.history.label.statusTracing") }}</label>
      </div>
      <div class="column">
        <label>{{ $t("shipment.history.label.comment") }}</label>
      </div>
    </div>
    <div v-for="note in shipmentHistory" :key="note.name" v-show="shipmentHistory?.length">
      <div class="history-block">
        <div class="history-content columns is-two-thirds-tablet is-desktop">
          <div class="history-content column">
            <div class="horizontal-layout align-center">
              <div>
                <the-circle-icon :icon="note.statusState === 0 ? 'fa-truck' : note.statusState === 1 ? 'fa-ellipsis' : 'fa-check'" :backgroundColor="note.statusState === 0 ? '#2C3E50' : '#C1C1C1'" />
              </div>
              <div class="info-content margin-left vertical-layout">
                <h4 :class="note.statusState === 0 ? 'bold margin-bottom' : 'margin-bottom'">
                  {{ note.statusName }}
                </h4>
                <div class="form-item mobile-visibility">
                  <label>{{ $t("shipment.history.label.planDate") }}</label>
                  <div class="margin-left">{{ formatDateField(note.statusPlanDate) }}</div>
                </div>
                <div class="form-item mobile-visibility">
                  <label>{{ $t("shipment.history.label.factDate") }}</label>
                  <div class="margin-left">{{ formatDateField(note.statusFactDate) }}</div>
                </div>
                <div class="form-item mobile-visibility">
                  <label>{{ $t("shipment.history.label.statusTracing") }}</label>
                  <div class="margin-left">{{ formatTextField(note.tracingStatusName) }}</div>
                </div>
                <div class="form-item mobile-visibility wrap">
                  <label>{{ $t("shipment.history.label.comment") }}</label>
                  <div class="margin-left">{{ note.commentContent }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="column desktop-visibility">
            <h4>{{ formatDateField(note.statusPlanDate) }}</h4>
          </div>
          <div class="column desktop-visibility">
            <h4>{{ formatDateField(note.statusFactDate) }}</h4>
          </div>
          <div class="column desktop-visibility">
            <h4>{{ formatTextField(note.tracingStatusName) }}</h4>
          </div>
          <div class="column desktop-visibility">
            <h4>{{ note.commentContent }}</h4>
          </div>
        </div>
        <div class="history-connect"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TheCircleIcon from "@/components/commonLib/TheCircleIcon.vue";
export default {
  components: { TheCircleIcon },
  props: {
    shipmentHistory: {
      type: Array
    }
  }
};
</script>

<style lang="scss">
.history-timeline {
  font-size: 0.725rem;

  .no-items {
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
  }

  label {
    color: #c1c1c1;
  }
}

.history-block {
  .history-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .info-content {
      align-items: flex-start;

      .form-item {
        flex-direction: row !important;
      }

      .form-item.wrap {
        flex-wrap: wrap;
      }
    }
  }

  .columns {
    margin-bottom: 0;
  }

  .history-connect {
    height: 1rem;
    margin: 0px 10px 10px 20px;
    border-left: 2px solid #c1c1c1;
  }
}

.mobile-visibility {
  display: none !important;
}

.history-timeline div:last-child .history-block .history-connect {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-visibility {
    display: flex !important;
  }
  .desktop-visibility {
    display: none !important;
  }
  .history-content {
    width: 100%;

    h1 {
      margin-bottom: 0.25rem;
    }

    .info-content {
      width: auto !important;
    }
  }
}
</style>
