<template>
  <div class="chat-window">
    <div v-for="dateGroup in groupedMessages" :key="dateGroup.group" class="date-message-group">
      <h3>{{ dateGroup.group }}</h3>
      <div v-for="message in dateGroup.messages" :key="message.messageId" :id="message.messageId">
        <message-item
          :isFirstFlag="message.isFirstFlag"
          :isLastFlag="message.isLastFlag"
          :isUserMessage="message.messageCreation.actionAuthor === $store.state.user.userFullname"
          :isUnread="!message.messageSeen"
          :isHasAttachment="!!message.attachments?.length"
          :attachments="message.messageAttachments"
          :messageContent="message.messageText"
          :messageDate="$moment(message.messageCreation?.actionDate).format('DD.MM.YYYY, HH:mm')"
          :messageAuthor="message.messageCreation.actionAuthor"
          :isNewMessage="!!message.bIsNew"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MockService from "@/services/MockService.js";
import MessageItem from "@/components/commonLib/MessageItem.vue";
export default {
  components: { MessageItem },
  props: {
    messages: {
      type: new Array()
    }
  },
  data() {
    return {
      lastReadMessageRef: "",
      aSeenMessages: []
    };
  },
  computed: {
    groupedMessages() {
      let aMessages = this.messages || [];
      aMessages = this.sortMessagesByTime(aMessages);
      //группируем сообщения по дате создания
      aMessages = this.createGroup(aMessages);
      return aMessages;
    }
  },
  updated() {
    this.findLastSeenMessage(this.sortMessagesByTime(this.messages));
    if (this.lastReadMessageRef) {
      const elem = document.getElementById(this.lastReadMessageRef);
      elem.scrollIntoView();
    }
  },
  methods: {
    sortMessagesByTime(aMessages) {
      let aSortMessages = [...aMessages];
      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      //сортируем сообщения по дате
      aSortMessages = aSortMessages.sort((a, b) => {
        if (new Date(a.messageCreation?.actionDate.replace(pattern, "$3-$2-$1")) > new Date(b.messageCreation?.actionDate.replace(pattern, "$3-$2-$1"))) return 1;
        if (new Date(a.messageCreation?.actionDate.replace(pattern, "$3-$2-$1")) < new Date(b.messageCreation?.actionDate.replace(pattern, "$3-$2-$1"))) return -1;
        return 0;
      });
      return aSortMessages;
    },
    createGroup(aArray) {
      let aGroupedArray = [];
      aArray.forEach((oItem) => {
        let aGrouped = aGroupedArray.find((oElem) => oElem.group === this.$moment(oItem.messageCreation?.actionDate).format("DD.MM.YYYY"));
        if (!aGrouped) {
          aGroupedArray.push({ group: this.$moment(oItem.messageCreation?.actionDate).format("DD.MM.YYYY"), messages: [oItem] });
        } else {
          aGrouped.messages.push(oItem);
        }
      });
      aGroupedArray.forEach((oGroup) => {
        let aMessages = oGroup.messages;
        aMessages.forEach((oMessage, index) => {
          let oPrevMessage = aMessages[index - 1];
          oMessage.isFirstFlag = true;
          oMessage.isLastFlag = true;
          if (!oPrevMessage) {
            return;
          }

          if (oPrevMessage.messageCreation.actionAuthor === oMessage.messageCreation.actionAuthor) {
            oPrevMessage.isLastFlag = false;
            oMessage.isLastFlag = true;
            oMessage.isFirstFlag = false;
          }
        });
      });

      return aGroupedArray;
    },
    findLastSeenMessage(aMessages) {
      let bFindFirstUnseenMessage = false;
      for (let message of aMessages) {
        bFindFirstUnseenMessage = !message.messageSeen && !message.messageMine;
        if (!bFindFirstUnseenMessage) {
          this.lastReadMessageRef = message.messageId;
        }
        if (bFindFirstUnseenMessage) {
          this.aSeenMessages.push(message.messageId);
        }
      }
      if (this.aSeenMessages.length) {
        const sId = this.$route.params.requestId;
        let oSeenMessagesBody = { messageIds: this.aSeenMessages };
        MockService.markMessageSeen(sId, oSeenMessagesBody)
          .then(() => {})
          .finally(() => {
            this.aSeenMessages = [];
          });
      }
    }
  }
};
</script>

<style lang="scss">
.chat-window {
  padding: 0.5rem;
  overflow-y: auto;
  scroll-behavior: revert;
}

.date-message-group {
  width: 100%;
}

.date-message-group > h3 {
  text-align: center;

  margin: 2rem 0;
}
</style>
