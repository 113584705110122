<template>
  <div class="create-req-block">
    <div class="form-req-block">
      <div class="form-item">
        <label>{{ $t("createReq.label.internalOrderId") }}</label>
        <div>{{ requestDetails?.general.orderInternalId }}</div>
      </div>
      <div class="form-item">
        <label class="required">{{ $t("createReq.label.title") }}</label>
        <div><b-input v-model="request.requestTitle" size="is-small"></b-input></div>
      </div>
      <div class="form-item">
        <label>{{ $t("createReq.label.text") }}</label>
        <div>
          <b-input
            v-model="request.requestMessageText"
            type="textarea"
            size="is-small"
            custom-class="reqTextArea"
          ></b-input>
        </div>
      </div>
    </div>
    <div class="req-footer">
      <b-button
        type="is-primary is-small"
        class="margin-left"
        @click="onSendRequest"
        >{{ $t("btn.send") }}</b-button
      >
    </div>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import MockService from "@/services/MockService";
export default {
  props: {
    requestDetails: {
      type: Object,
    },
  },
  data() {
    return {
      request: {
        requestMessageText: "",
        requestTitle: ""
      },
      isLoading: false
    };
  },
  methods: {
    onSendRequest() {
     if (!this.request.requestTitle){
      return this.errorMessage(this.$t("shipment.warning.request.create.title"));
     }
     this.isLoading = true;
     this.$emit("banCloseDialog");
      MockService.createRequest(this.requestDetails.general.orderId, this.request)
      .then((oMessageResponse) => {
        this.$emit("successfullySent", oMessageResponse.data.requestId);
        this.successMessage(this.$t("success.createdRequest"));
        this.request = {
          requestMessageText: "",
          requestTitle: "",
        };
      })
      .catch((error) => {
        this.errorMessage(error.response?.data.errorMessage)
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
.create-req-block {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  .form-req-block {
    width: 100%;

    display: flex;
    flex-direction: column;

    .form-item {
      width: 100%;

      div {
        width: 100%;
      }

      .required:after {
        content: " *";
        color: red;
      }
    }

    .reqTextArea {
      resize: none;
    }
  }

  .req-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
