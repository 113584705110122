<template>
  <div>
    <div class="horizontal-layout customMultiSelect" :style="{ width: width }">
      <b-taginput
        v-model="newSelectedItems"
        :data="items"
        ellipsis
        :field="textBinding"
        autocomplete
        size="is-small"
        :placeholder="newSelectedItems.length ? '' : placeholder"
        readonly
        class="customInput"
        :style="{ width: 'calc(' + width + ' - 2rem)' }"
      >
        <template #tag="props">
          <span>{{ formatterEmpty(props.tag[textBinding]) }}</span>
        </template>
      </b-taginput>
      <b-dropdown ref="dropdown" size="is-small" v-model="newSelectedItems" multiple :mobile-modal="false" position="is-bottom-left" class="customDropDown" scrollable max-height="220" area-role="list" @active-change="changeActiveItems">
        <template #trigger> <b-button size="is-small" icon-right="chevron-down" class="customButton" /> </template>
        <b-dropdown-item size="is-small" v-for="item in items" :key="item[keyBinding]" :value="item" :style="{ width: width }" area-role="listitem" :ref="'item'+item[keyBinding]">{{ formatterEmpty(item[textBinding]) }}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "10rem"
    },
    keyBinding: {
      type: String,
      required: true
    },
    textBinding: {
      type: String,
      required: true
    },
    emptyItemMask: {
      type: String,
      default: "—"
    }
  },
  watch: {
    selectedItems(newValue) {
      this.newSelectedItems = newValue;
    },
    newSelectedItems() {
      this.$emit("change", this.newSelectedItems);
    }
  },
  data() {
    return {
      newSelectedItems: this.selectedItems
    };
  },
  methods: {
    formatterEmpty(sText) {
      if (sText || Number.isInteger(sText)) {
        return sText;
      } else {
        return this.emptyItemMask;
      }
    },
    changeActiveItems() {
          let sTempObj = [].concat(this.newSelectedItems);
        this.$refs.dropdown.selected = [];
        sTempObj.forEach(oSelected=>{
            let oDr = this.$refs['item'+oSelected[this.keyBinding]];
            if (oDr && oDr[0]) {
                oDr[0].selectItem();
            }
        });        
        
      }
  }
};
</script>

<style lang="scss">
.customMultiSelect {
  border: 1px solid #2c3e50;
  border-radius: 4px;

  .taginput-container.is-small.is-focusable {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    //  border-right: 0;
    border: none;
    width: inherit;
  }

  .taginput .taginput-container.is-focusable {
    flex-wrap: unset;
    overflow: hidden;
  }

  .dropdown .button.is-small {
    border-left: none;
    border-top-left-radius: 0;
    border: none;
    border-bottom-left-radius: 0;
  }

  a.dropdown-item.is-active {
    background: #2c3e50;
  }

  .customButton.button .icon,
  .customButton.button .icon.is-small {
    height: 1.2rem;
    width: 1.2rem;
  }
}
</style>
